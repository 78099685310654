import React from 'react'
import {
  Image,
  Link,
  Box,
  Pill,
  HeartToggle,
} from '@moonpig/launchpad-components'
import { styled, breakpointUp } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { useLocaleText } from '../ProductTile.locale'
import { PillVariant } from '../../../types'
import { SponsoredPill } from '../../SponsoredPill'

const StyledImage = styled(Image)`
  display: block;
  width: 100%;
  ${s({ boxShadow: 2 })}
  transform: translateY(0);
  transition: all 200ms ease-in-out;
  ${breakpointUp('md')} {
    &:hover {
      ${s({ boxShadow: 4 })}
      ${({ theme }) => `transform: translateY(-${theme.spacing[3]}px);`}
    }
  }
`

const StyledBox = styled(Box)`
  position: relative;
  top: 100%;
  transform: translateY(-100%);
`

const StyledPillWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
  transform: translateY(-50%);
  z-index: 1;
`

const StyledHeartToggleWrapper = styled.div`
  ${s({
    position: 'absolute',
    zIndex: 1,
    bottom: 4,
    right: 4,
  })}
`

type CardProductTileGalleryVariantProps = {
  href: string
  image: {
    src: string
    alt: string
  }
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void
  ref?: React.Ref<HTMLAnchorElement>
  /** Determines the card image aspect ratio */
  portraitCardAspectRatio?: number
  /** Determines the favourite toggle state props */
  favourite?: {
    /** State of the favourite toggle */
    isSelected?: boolean
    /** Callback function to change the state of the favourite toggle */
    onSelect: () => void
  }
  label?: string
  variant?: PillVariant
  icon?: React.ReactElement
  sponsored?: boolean
}

export const CardProductTileGalleryVariant = React.forwardRef(
  (
    props: CardProductTileGalleryVariantProps,
    ref?: React.Ref<HTMLAnchorElement>,
  ) => {
    const {
      href,
      image,
      onClick,
      portraitCardAspectRatio,
      favourite,
      label,
      variant,
      icon,
      sponsored,
    } = props
    const localiseText = useLocaleText()

    return (
      <StyledBox>
        {favourite && (
          <StyledHeartToggleWrapper>
            <HeartToggle
              isToggled={!!favourite.isSelected}
              onToggle={() => {
                favourite.onSelect()
              }}
              ariaLabel={
                favourite.isSelected
                  ? `${localiseText(
                      'card_product_tile.remove_from_favourites',
                    )}`
                  : `${localiseText('card_product_tile.add_to_favourites')}`
              }
            />
          </StyledHeartToggleWrapper>
        )}
        {sponsored && (
          <StyledPillWrapper>
            <SponsoredPill />
          </StyledPillWrapper>
        )}
        <Link
          href={href}
          onClick={onClick}
          ref={ref}
          data-testid="shared-products-card-product-tile-gallery-variant"
        >
          {!sponsored && label && (
            <StyledPillWrapper>
              <Pill label={label} variant={variant} icon={icon} />
            </StyledPillWrapper>
          )}
          <StyledImage aspectRatio={portraitCardAspectRatio} {...image} />
        </Link>
      </StyledBox>
    )
  },
)

CardProductTileGalleryVariant.displayName = 'CardProductTileGalleryVariant'
