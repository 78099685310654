import { Box, Text } from '@moonpig/launchpad-components'
import React from 'react'
import { Markup } from '@moonpig/web-core-utils'
import { styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { ModuleList } from '../types'

type Props = {
  module: ModuleList
}

const StyledBulletedList = styled.ul`
  list-style: disc;
  ${s({ ml: { xs: 7 } })}

  li {
    ${s({ my: 4 })}
  }
`

export const ListModule: React.FC<Props> = ({
  module: { title, items },
}): JSX.Element => {
  return (
    <Box data-testid="module-list" mb={6}>
      <Text
        typography={{ xs: 'typeMobileDisplay03', md: 'typeDesktopDisplay04' }}
      >
        {title}
      </Text>
      <StyledBulletedList>
        {items.map((item, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <li key={index}>{Markup({ htmlString: item })}</li>
        ))}
      </StyledBulletedList>
    </Box>
  )
}
