import { gql, useQuery } from '@moonpig/web-core-graphql'
import {
  GetProductWithAddonsGQLQuery as GetProductWithAddonsGQLType,
  GetProductWithAddonsGQLQueryVariables,
} from './__generated__/useProductWithAddonsQuery'

export const ADDONS_FIELDS_FRAGMENT = gql`
  fragment AddonsFields on Addon {
    title
    description
    sku
    inStock
    price {
      display
      centAmount
      currencyCode
    }
    image {
      url
    }
  }
`

export const GetProductWithAddonsGQL = gql`
  ${ADDONS_FIELDS_FRAGMENT}

  query GetProductWithAddonsGQL($productIds: [String]) {
    productLookup(productIds: $productIds) {
      products {
        masterVariant {
          addons(clientUsesDescription: true) {
            ...AddonsFields
          }
        }
        variants {
          sku
          addons(clientUsesDescription: true) {
            ...AddonsFields
          }
        }
      }
    }
  }
`

export const useProductWithAddonsQuery = (productId: string) => {
  const result = useQuery<
    GetProductWithAddonsGQLType,
    GetProductWithAddonsGQLQueryVariables
  >(GetProductWithAddonsGQL, {
    variables: {
      productIds: [productId],
    },
  })

  return result.data
}
