import { hasRefreshToken } from '@moonpig/web-core-auth'
import { useLazyQuery, gql } from '@moonpig/web-core-graphql'
import { getBrowserCookies } from '@moonpig/web-core-cookies'

export const BasketTotalItemsQuery = gql`
  query getBasketTotalItems {
    me {
      basket {
        __typename
        id
        totalItems
      }
    }
  }
`

export const useBasketTotalItems = () => {
  const hasUserGotRefreshToken = hasRefreshToken(getBrowserCookies())
  const [getTotalItems, { data, called, previousData }] = useLazyQuery(
    BasketTotalItemsQuery,
    {
      ssr: false,
      fetchPolicy: 'network-only',
    },
  )

  if (hasUserGotRefreshToken && !called) getTotalItems()

  const totalItems =
    data?.me?.basket?.totalItems || previousData?.me?.basket?.totalItems || 0

  return {
    basketTotalItems: totalItems,
    getTotalItems: hasUserGotRefreshToken ? getTotalItems : () => 0,
  }
}
