import React, { useEffect, useState, type FC } from 'react'
import { Box, SimpleCarousel, Text } from '@moonpig/launchpad-components'
import { useCoreLocaleText } from '@moonpig/web-core-locale-text'
import { breakpointUp, styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { useTrackGAEventOnce } from '@moonpig/web-core-analytics'
import { ModuleContext, ModulePeopleAlsoViewedVariant } from '../types'
import { useProductStore } from '../../contexts/product'
import { usePeopleAlsoViewedQuery } from '../../queries/PeopleAlsoViewed'
import { useTracking } from '../../contexts/tracking'

const StyledBox = styled(Box)`
  ${s({
    overflow: 'hidden',
    mx: { xs: -5, md: -10 },
    mt: { md: 10 },
    mb: { md: -10 },
    px: { md: 10 },
    pb: { xs: 3, md: 8 },
    bgcolor: 'colorBackground06',
  })}
`

const StyledImage = styled.img`
  display: block;
  width: 100%;
  max-width: 120px;
  ${breakpointUp('md')} {
    max-width: 170px;
  }
`

type Props = {
  module: ModulePeopleAlsoViewedVariant
  context: ModuleContext
}

export const PeopleAlsoViewedVariantModule: FC<Props> = () => {
  const [{ initialProduct }, dispatcher] = useProductStore()
  const products = usePeopleAlsoViewedQuery({
    product: initialProduct,
    limit: 10,
  })

  const l = useCoreLocaleText()
  const tracking = useTracking()
  const { trackGAEventOnce } = useTrackGAEventOnce()
  const [scrollWasTracked, setScrollWasTracked] = useState(false)
  const moduleTitle = l('common.people_also_viewed')

  const handleScroll = () => {
    if (!scrollWasTracked) {
      trackGAEventOnce({
        event: 'select_content',
        content_data: {
          content_type:
            'product action | carousel scroll right | product modal | people also viewed',
          item_id: initialProduct.id,
        },
        event_data: {
          category: 'product action',
          action: 'carousel scroll right',
          label: `product modal | people also viewed | carousel | ${initialProduct.id}`,
          non_interaction: true,
        },
      })

      setScrollWasTracked(true)
    }
  }

  const handleClick = (index: number) => {
    const product = products[index]

    dispatcher.setProduct(product)
    tracking.selectProduct({
      product,
      list: {
        name: moduleTitle,
        index: 0,
        totalNumberOfLists: 1,
        length: products.length,
      },
      variant: product.masterVariant,
      index,
      component: 'carousel',
    })
  }

  useEffect(() => {
    if (products.length) {
      tracking.viewProductList({
        products,
        listName: moduleTitle,
        component: 'carousel',
      })
    }
  }, [moduleTitle, products, tracking])

  return products.length ? (
    <StyledBox data-testid="module-people-also-viewed-variant">
      <Text
        as="p"
        my={6}
        mx={{ xs: 5, md: 0 }}
        typography={{ xs: 'typeMobileDisplay04', md: 'typeDesktopDisplay05' }}
        color={'colorTextHeadline'}
      >
        {moduleTitle}
      </Text>
      <Box px={{ xs: 0, md: 0 }} mx={{ xs: 0, md: -6 }}>
        <SimpleCarousel
          gap={6}
          aria-label={moduleTitle}
          onScroll={handleScroll}
        >
          {products.map(({ id, title, masterVariant }, index, { length }) => {
            return (
              <Box
                key={id}
                pl={{ xs: index === 0 ? 5 : 0, md: 0 }}
                pr={{ xs: index === length - 1 ? 5 : 0, md: 0 }}
              >
                <button
                  type="button"
                  onClick={() => handleClick(index)}
                  aria-label={l('common.button_to_select_title', {
                    title,
                  })}
                >
                  <StyledImage
                    alt={title}
                    src={masterVariant.masterImage.url}
                  />
                </button>
              </Box>
            )
          })}
        </SimpleCarousel>
      </Box>
    </StyledBox>
  ) : null
}
