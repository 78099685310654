import React from 'react'
import { SponsoredPill } from '../../SponsoredPill'
import { TilePill as Pill } from './components/Pill'
import { PillVariant } from '../../../types'

type Props = {
  isSponsored: boolean
  pill:
    | {
        label: string
        variant: PillVariant
      }
    | undefined
}

export const createPill = ({ isSponsored, pill }: Props) => {
  if (isSponsored) {
    return <SponsoredPill />
  }
  if (pill) {
    return <Pill pill={pill} />
  }

  return null
}
