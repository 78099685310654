import React, { FC, useEffect, useRef } from 'react'
import { styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { Box } from '@moonpig/launchpad-components'
import { useProductStore } from '../../contexts/product'

const StyledBox = styled(Box)`
  width: 850px;
  ${s({
    pb: { xs: '81px', md: 0 },
  })}
`

export const ScrollToTop: FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const contentRef = useRef<HTMLDivElement | null>(null)
  const [{ product }] = useProductStore()

  useEffect(() => {
    contentRef.current?.scrollIntoView?.({
      behavior: 'smooth',
      block: 'start',
    })
  }, [product])

  return <StyledBox ref={contentRef}>{children}</StyledBox>
}
