import { logger } from '@moonpig/web-core-monitoring'
import {
  AddToBasketAndSplitMutation,
  AddToBasketError,
  AddToBasketMutation,
  AddToBasketMutationResponse,
} from './types'

type HandledMutationParams = {
  id: string
  sku: string
  quantity: number
  addonSku: string | undefined
  addToBasketMutation: AddToBasketMutation | AddToBasketAndSplitMutation
  defaultErrorMessage: string
  concurrentModificationErrorMessage: string
}

type AddToBasketMutationResponseDerivatives = {
  isBasketTypeInResponse?: boolean
  isGreetingsCardsInBasket?: boolean
}

type AddToBasketHandledResult = AddToBasketError &
  AddToBasketMutationResponse &
  AddToBasketMutationResponseDerivatives

export const handleMutation = async ({
  id,
  sku,
  quantity,
  addonSku,
  addToBasketMutation,
  defaultErrorMessage,
  concurrentModificationErrorMessage,
}: HandledMutationParams): Promise<AddToBasketHandledResult> => {
  const { data, errors } = await addToBasketMutation({
    variables: {
      input: {
        quantity,
        sku,
        addons: addonSku ? [{ sku: addonSku }] : [],
      },
    },
  })
  if (errors || !data) {
    logger.fixToday(
      `Failed to add item to basket - ${
        errors ? JSON.stringify(errors) : 'No data returned'
      }`,
    )
  }

  const response = data?.addToBasket

  switch (response?.__typename) {
    case 'Basket': {
      return {
        isBasketTypeInResponse: response?.__typename === 'Basket',
        isGreetingsCardsInBasket: response?.lineItems?.some(
          lineItem => lineItem.productType === 'greetingcard',
        ),
        basketId: response.id,
        totalItems: response.totalItems,
      }
    }
    case 'ConcurrentModificationError':
      return {
        error: concurrentModificationErrorMessage,
      }
    case 'ProductNotFoundError':
    default:
      logger.warning(`Product not found "${id}"`)
      break
  }

  return {
    error: defaultErrorMessage,
  }
}
