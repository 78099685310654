import React, { FC } from 'react'
import { Box, Grid } from '@moonpig/launchpad-components'
import { styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { useCoreLocaleText } from '@moonpig/web-core-locale-text'
import { Recommendation } from '../../types'

const StyledImage = styled.img`
  display: block;
  width: 100%;
`
const StyledButton = styled.button`
  width: 100%;
  ${s({ padding: 3 })}

  &.is-active {
    ${s({ borderBottom: 2, borderColor: 'colorInteractionButton' })}
  }

  &.is-first {
    ${s({ border: 2, color: 'colorInteractionButton' })}
  }
`

type Props = {
  recommendations: Recommendation[]
  onClick: (index: number) => void
}

export const Recommendations: FC<Props> = ({ recommendations, onClick }) => {
  const localiseText = useCoreLocaleText()
  const [activeIndex, setActiveIndex] = React.useState(-1)
  const heading = localiseText('common.people_also_viewed')

  return (
    <Grid gap={5}>
      <Box
        width={1}
        typography={{ xs: 'typeMobileBodyLabel', md: 'typeDesktopBodyText' }}
        textAlign={{ xs: 'left', md: 'center' }}
      >
        <span>{heading}</span>
      </Box>
      {recommendations.map((recommendation, index) => {
        const { url, title, id } = recommendation
        const productsLength = recommendations.length
        let className
        if (activeIndex === index) className = 'is-active'
        if (index === 0) className = 'is-first'

        return (
          <Box key={id} width={1 / productsLength} display="inline-block">
            <StyledButton
              className={className}
              onClick={() => {
                setActiveIndex(index)
                onClick(index)
              }}
              aria-label={localiseText('common.button_to_select_title', {
                title,
              })}
            >
              <StyledImage alt={title} src={url} />
            </StyledButton>
          </Box>
        )
      })}
    </Grid>
  )
}
