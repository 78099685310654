import { useEffect, useState, useMemo } from 'react'
import { useStore } from '@moonpig/web-core-stores'
import { useCoreLocaleText } from '@moonpig/web-core-locale-text'
import { calculateCutOff } from './calculateCutOff'
import { DispatchCutOff } from '../../types'

export const useDispatchCutOffTimer = (
  dispatchCutOff: DispatchCutOff | null,
) => {
  const { store } = useStore()
  const localise = useCoreLocaleText()

  const state = useMemo(
    () => calculateCutOff(store, dispatchCutOff, localise),
    [store, dispatchCutOff, localise],
  )

  const [{ isVisible, message }, setState] = useState(state)

  useEffect(() => {
    setState(calculateCutOff(store, dispatchCutOff, localise))
    if (!dispatchCutOff) return

    const interval = setInterval(() => {
      setState(calculateCutOff(store, dispatchCutOff, localise))
    }, 10000)
    return () => clearInterval(interval)
  }, [store, dispatchCutOff, localise])

  return { isVisible, message }
}
