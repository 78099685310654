import { gql } from '@moonpig/web-core-graphql'
import { designFields } from '../addDigitalGiftToBasket/fragments'

export const AddToBasketAndSplitGQL = gql`
  mutation AddToBasketAndSplitFind($input: AddToBasketAndSplitInput!) {
    addToBasket: addToBasketAndSplit(input: $input) {
      __typename
      ... on Basket {
        id
        lineItems {
          productType
          id
          design {
            ...DesignFields
          }
        }
        totalItems
      }
      ... on ConcurrentModificationError {
        message
      }
      ... on ProductNotFoundError {
        message
      }
    }
  }
  ${designFields}
`
