import { gql } from '@moonpig/web-core-graphql'

const DispatchCutOffFragment = {
  fields: gql`
    fragment DispatchCutOffFields on ContentDepartment {
      dispatchCutOff {
        weekdays {
          message
          time
        }
        weekendsAndBankHolidays {
          message
          time
        }
      }
    }
  `,
}

export const DispatchCutOffGQL = gql`
  query DispatchCutOffQuery(
    $filter: [ID!]
    $preview: Boolean
    $region: Region
  ) {
    contentDepartments(filter: $filter, preview: $preview, region: $region) {
      id
      ...DispatchCutOffFields
      subdepartments {
        id
        ...DispatchCutOffFields
      }
    }
  }
  ${DispatchCutOffFragment.fields}
`
