import { Module } from '../../types'

export const createCardUpsellSimplificationMobileConfiguration =
  (): Module[] => {
    return [
      { __typename: 'ModuleImages' },
      { __typename: 'ModuleMemberPricingHeader' },
      {
        __typename: 'ModuleVariantTabs',
        modules: [
          { __typename: 'ModuleQuantity' },
          { __typename: 'ModulePromotions' },
          { __typename: 'ModuleDispatch' },
        ],
      },
      { __typename: 'ModuleMoreInfo' },
      { __typename: 'ModulePeopleAlsoViewedVariant' },
      { __typename: 'ModuleButtons' },
    ]
  }
