import { GIFT_UPSELL_PRODUCT_IDS } from '@moonpig/web-core-brand/config'

export const getGiftUpsellProductId = (
  productId: string,
): string | undefined => {
  const normalisedProductId = productId.toLowerCase()
  const upsellProduct = GIFT_UPSELL_PRODUCT_IDS.find(
    ({ originalId }) => normalisedProductId === originalId.toLowerCase(),
  )
  return upsellProduct?.upsellId
}
