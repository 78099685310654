import {
  E_CARD_SIZE_ID,
  GIANT_CARD_SIZE_ID,
  STANDARD_CARD_SIZE_ID,
  LARGE_CARD_SIZE_ID,
  POSTCARD_SIZE_ID,
} from '../../constants'

export const isPortraitCard = (
  productSizeId: string,
  isLandscapeCard: boolean | undefined,
) => {
  if (
    productSizeId === STANDARD_CARD_SIZE_ID ||
    productSizeId === GIANT_CARD_SIZE_ID ||
    productSizeId === E_CARD_SIZE_ID ||
    productSizeId === LARGE_CARD_SIZE_ID ||
    productSizeId === POSTCARD_SIZE_ID
  ) {
    return !isLandscapeCard
  }
  return false
}
