import React from 'react'
import { Box } from '@moonpig/launchpad-components'
import { FromPrice } from '../../components/FromPrice'
import { useProductStore } from '../../contexts/product'

export const FromPriceModule = () => {
  const [
    {
      product: { masterVariant },
    },
  ] = useProductStore()

  return (
    <Box data-testid="module-from-price" mb={6}>
      <FromPrice
        priceCentAmount={masterVariant.price.centAmount}
        currencyCode={masterVariant.price.currencyCode}
        fractionDigits={masterVariant.price.fractionDigits}
      />
    </Box>
  )
}
