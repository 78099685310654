/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-restricted-syntax */
const unset = Symbol.for('unset')

type PickReturnValue<a, b> = a extends typeof unset ? b : a
type Union<a, b> = [b] extends [a] ? a : [a] extends [b] ? b : a | b

type Match<TInput, TOutput, T = never> = {
  with: <C>(
    pattern: Partial<TInput>,
    handler: (value?: TInput) => PickReturnValue<TOutput, C>,
  ) => Match<TInput, TOutput, Union<T, C>>
  evaluate: () => PickReturnValue<TOutput, T>
  otherwise: <C>(
    handler: (value?: TInput) => PickReturnValue<TOutput, C>,
  ) => PickReturnValue<TOutput, T>
}

export const match = <TInput, TOutput = typeof unset>(
  input: TInput,
): Match<TInput, TOutput> => {
  const patterns: [object, any][] = []

  const evaluateFn: Match<TInput, TOutput>['evaluate'] = () => {
    for (const [pattern, handler] of patterns) {
      if (
        Object.entries(pattern).every(
          ([key, value]) => (input as any)[key] === value,
        )
      ) {
        return handler(input) as ReturnType<Match<TInput, TOutput>['evaluate']>
      }
    }

    throw new Error('No matching pattern')
  }

  const otherwiseFn: Match<TInput, TOutput>['otherwise'] = otherwiseHandler => {
    for (const [pattern, handler] of patterns) {
      if (
        Object.entries(pattern).every(
          ([key, value]) => (input as any)[key] === value,
        )
      ) {
        return handler(input) as ReturnType<Match<TInput, TOutput>['evaluate']>
      }
    }

    return otherwiseHandler(input) as ReturnType<
      Match<TInput, TOutput>['evaluate']
    >
  }

  const withFn: Match<TInput, TOutput>['with'] = (pattern, handler) => {
    patterns.push([pattern, handler])

    return {
      with: withFn,
      evaluate: evaluateFn,
      otherwise: otherwiseFn,
    }
  }

  return {
    with: withFn,
    evaluate: evaluateFn,
    otherwise: otherwiseFn,
  }
}
