import React, { FC } from 'react'
import { Box } from '@moonpig/launchpad-components'
import { SoldOutBanner } from '../../components/SoldOutBanner'

export const OutOfStockModule: FC = () => {
  return (
    <Box data-testid="module-out-of-stock" mb={6}>
      <SoldOutBanner />
    </Box>
  )
}
