import React from 'react'
import type { ModuleContext, Module } from './types'
import { MultiBuyTabsModule } from './MultiBuyTabs'
import { TwoColumnModule } from './TwoColumn'
import { TitleModule } from './Title'
import { VariantsModule } from './Variants'
import { DispatchModule } from './Dispatch'
import { ButtonsModule } from './Buttons'
import { MoreInfoModule } from './MoreInfo'
import { QuantityModule } from './Quantity'
import { PromotionsModule } from './Promotions'
import { ImagesModule } from './Images'
import { LinkModule } from './Link'
import { PriceModule } from './Price'
import { PeopleAlsoViewedModule } from './PeopleAlsoViewed'
import { PillsModule } from './Pills'
import { RatingModule } from './Rating'
import { ModuleErrorBoundary } from './ErrorBoundary'
import { ThingsToKnowModule } from './ThingsToKnow/ThingsToKnowModule'
import { GroupCardTabsModule } from './GroupCardTabs'
import { ListModule } from './List'
import { AddonsModule } from './Addons'
import { UpsellModule } from './Upsell'
import { VariantsExperimentModule } from './VariantsExperiment/VariantsExperimentModule'
import { PeopleAlsoViewedVariantModule } from './PeopleAlsoViewedVariant'
import { MemberPricingHeaderModule } from './MemberPricingHeader/MemberPricingHeader'
import { OutOfStockModule } from './OutOfStock'
import { FromPriceModule } from './FromPriceModule'
import { VariantTabsModule } from './VariantTabs'
import { KlarnaInfoModule } from './KlarnaInfoModule'

const map = (m: Module, context: ModuleContext) => {
  switch (m.__typename) {
    case 'ModuleTwoColumn':
      return <TwoColumnModule module={m} map={mapModule} context={context} />

    case 'ModuleMultiBuyTabs':
      return (
        <MultiBuyTabsModule
          tabModule={m}
          context={context}
          mapModule={mapModule}
        />
      )

    case 'ModuleGroupCardTabs':
      return (
        <GroupCardTabsModule
          tabModule={m}
          context={context}
          mapModule={mapModule}
        />
      )

    case 'ModuleVariantTabs':
      return (
        <VariantTabsModule module={m} context={context} mapModule={mapModule} />
      )

    case 'ModuleTitle':
      return <TitleModule module={m} context={context} />

    case 'ModuleVariants':
      return <VariantsModule module={m} context={context} />

    case 'ModuleVariantsExperiment':
      return <VariantsExperimentModule module={m} context={context} />

    case 'ModuleDispatch':
      return <DispatchModule module={m} />

    case 'ModuleButtons':
      return <ButtonsModule module={m} context={context} />

    case 'ModuleMoreInfo':
      return <MoreInfoModule module={m} context={context} />

    case 'ModuleQuantity':
      return <QuantityModule module={m} context={context} />

    case 'ModulePromotions':
      return <PromotionsModule module={m} context={context} />

    case 'ModuleImages':
      return <ImagesModule module={m} context={context} />

    case 'ModuleLink':
      return <LinkModule module={m} context={context} />

    case 'ModulePrice':
      return <PriceModule module={m} context={context} />

    case 'ModuleRating':
      return <RatingModule module={m} context={context} />

    case 'ModuleThingsToKnow':
      return <ThingsToKnowModule module={m} context={context} />

    case 'ModulePeopleAlsoViewed':
      return <PeopleAlsoViewedModule module={m} context={context} />

    case 'ModulePeopleAlsoViewedVariant':
      return <PeopleAlsoViewedVariantModule module={m} context={context} />

    case 'ModulePills':
      return <PillsModule module={m} context={context} />

    case 'ModuleList':
      return <ListModule module={m} />

    case 'ModuleAddons':
      return <AddonsModule module={m} context={context} />

    case 'ModuleUpsell':
      return <UpsellModule module={m} context={context} />

    case 'ModuleMemberPricingHeader':
      return <MemberPricingHeaderModule module={m} context={context} />

    case 'ModuleOutOfStock':
      return <OutOfStockModule />

    case 'ModuleFromPrice':
      return <FromPriceModule />

    case 'ModuleKlarnaInfo':
      return <KlarnaInfoModule />

    /* istanbul ignore next */
    default:
      return null
  }
}

export const mapModule = (m: Module, context: ModuleContext) => {
  const component = map(m, context)

  /* istanbul ignore else */
  if (component) {
    return (
      <ModuleErrorBoundary key={context.key} type={m.__typename}>
        {component}
      </ModuleErrorBoundary>
    )
  }

  /* istanbul ignore next */
  return null
}
