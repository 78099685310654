import { trackGAEvent } from '@moonpig/web-core-analytics'
import { useMutation } from '@moonpig/web-core-graphql'

import {
  AddToBasketFind,
  AddToBasketFindVariables,
  AddToBasketGQL,
} from '../../queries/addToBasket'
import { AddToBasketFind_addToBasket_Basket_lineItems } from '../types-graphql'

export type AddCardToBasketAndAssignDesignProps = {
  designId: string
  cardSku: string
  addonSku?: string
  quantity?: number
}

export type AddCardToBasketAndAssignDesignResponse = Promise<{
  cardLineItem: AddToBasketFind_addToBasket_Basket_lineItems
  basketId: string
  totalItems: number
  isGreetingsCardsInBasket: boolean
  isBasketTypeInResponse: boolean
}>

export const useAddCardToBasketAndAssignDesign = () => {
  const [addCardToBasket, { loading }] = useMutation<
    AddToBasketFind,
    AddToBasketFindVariables
  >(AddToBasketGQL)

  const addCardToBasketAndAssignDesign = async ({
    designId,
    cardSku,
    addonSku,
    quantity,
  }: AddCardToBasketAndAssignDesignProps): AddCardToBasketAndAssignDesignResponse => {
    const addToBasketResult = await addCardToBasket({
      variables: {
        input: {
          quantity: quantity || 1,
          sku: cardSku,
          designId,
          addons: addonSku ? [{ sku: addonSku }] : null,
        },
      },
    })

    if (addToBasketResult?.data?.addToBasket?.__typename !== 'Basket') {
      throw new Error('Could not add card to the basket')
    }

    trackGAEvent({
      event: 'personalise',
      eventData: {
        category: 'editor',
        action: 'personalise',
        label: `gallery | ${cardSku.toLowerCase()}`,
        value: undefined,
        non_interaction: true,
      },
      content_data: {
        content_type: 'editor | personalise',
        item_id: cardSku.toLowerCase(),
      },
      ecommerce: undefined,
    })

    const basket = addToBasketResult.data.addToBasket
    return {
      cardLineItem: basket.lineItems[basket.lineItems.length - 1],
      basketId: basket.id,
      totalItems: basket.totalItems,
      isGreetingsCardsInBasket: basket.lineItems?.some(
        lineItem => lineItem.productType === 'greetingcard',
      ),
      isBasketTypeInResponse:
        addToBasketResult?.data?.addToBasket?.__typename === 'Basket',
    }
  }

  return { addCardToBasketAndAssignDesign, addingToBasket: loading }
}
