/* eslint-disable react/no-array-index-key */
import React, { FC, useState } from 'react'
import { Grid, Box, Chip } from '@moonpig/launchpad-components'
import { ModuleContext, Module, ModuleTabs, Tab } from '../types'

type Props = {
  module: ModuleTabs
  context: ModuleContext
  mapModule: (module: Module, props: ModuleContext) => JSX.Element | null
  onChanged?: (tab: Tab, index: number) => void
  'data-testid'?: string
}

export const TabsModule: FC<Props> = ({
  module: { tabs },
  context,
  mapModule,
  onChanged,
  'data-testid': testId = 'module-tabs',
}) => {
  const [selectedIndex, setSelectedIndex] = useState(0)

  return (
    <Box data-testid={testId}>
      <Grid justifyContent={{ xs: 'center', md: 'start' }}>
        {tabs.map(({ title, label }, index) => (
          <Box mr={5} mb={6} key={index}>
            <Chip
              selected={selectedIndex === index}
              onClick={() => {
                if (selectedIndex !== index) {
                  setSelectedIndex(index)
                  onChanged?.(tabs[index], index)
                }
              }}
              aria-label={label}
            >
              {title}
            </Chip>
          </Box>
        ))}
      </Grid>
      <Box>
        {tabs[selectedIndex]?.modules.map((m, key) =>
          mapModule(m as Module, { ...context, key }),
        )}
      </Box>
    </Box>
  )
}
