import React, { FC } from 'react'
import { Box } from '@moonpig/launchpad-components'
import { useProductStore } from '../../contexts/product'
import { ModuleContext, ModuleUpsell } from '../types'
import { ProductUpsell } from '../../components/ProductUpsell'

type Props = {
  context: ModuleContext
  module: ModuleUpsell
}

export const UpsellModule: FC<Props> = () => {
  const [{ product, variant, upsellProduct }, dispatcher] = useProductStore()
  return upsellProduct ? (
    <Box data-testid="module-upsell" mb={6}>
      <ProductUpsell
        upsellProduct={upsellProduct}
        selectedVariantPrice={variant.price}
        onClick={event => {
          event.preventDefault()
          dispatcher.setProduct(upsellProduct)
        }}
        trackingData={{
          pageLocation: 'product details',
          product: { id: product.id },
        }}
      />
    </Box>
  ) : null
}
