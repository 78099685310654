import { LocaleTextHook } from '@moonpig/web-core-locale-text'
import { DepartmentsEnum } from '@moonpig/web-shared-types-graphql/graphqlTypes'
import { PageTypeEnum, ProductInfoProduct } from '../../types'
import { CanSeeCardOptionsResult } from '../../utils/useDigitalGiftingCardOptions/useDigitalGiftingCardOptions'
import { PRODUCT_INFO_TEXT } from '../../components/ProductInfo/ProductInfo.locale'
import { getPrimaryButtonText } from './getPrimaryButtonText'

type Buttons = {
  primaryButton: {
    onClick: () => void
    text: string
  }
  secondaryButton?: {
    onClick: () => void
    text: string
  }
}

type CreateButtonsArgs = {
  pageType: PageTypeEnum
  productType: string | undefined
  product: ProductInfoProduct
  groupCardProjectId: string | undefined
  localiseProductInfoText: LocaleTextHook<typeof PRODUCT_INFO_TEXT>
  getCanSeeCardOptions: (department: DepartmentsEnum) => CanSeeCardOptionsResult
  buttonActions: {
    personalise: () => void
    showCardOptionsForDigitalGift: () => void
    addDigitalGiftCardToBasket: () => void
    addCrossSellGiftToBasket: () => void
    addBasketCrossSellGiftToBasket: () => void
    onAddToBasket: () => void
    createGroupCard: () => void
  }
  showSecondaryButton: boolean
}

export const createButtons = ({
  pageType,
  productType,
  product,
  groupCardProjectId,
  localiseProductInfoText,
  getCanSeeCardOptions,
  buttonActions,
  showSecondaryButton,
}: CreateButtonsArgs): Buttons => {
  const primaryButtonText = getPrimaryButtonText({
    product,
    productType,
    groupCardProjectId,
    localiseText: localiseProductInfoText,
    getCanSeeCardOptions,
  })

  const { canSeeCardOptions } = getCanSeeCardOptions(
    product.category.department,
  )

  switch (pageType) {
    case PageTypeEnum.ADD_A_CARD:
      return {
        primaryButton: {
          onClick:
            productType === 'groupCard' || groupCardProjectId
              ? buttonActions.createGroupCard
              : buttonActions.personalise,
          text: primaryButtonText,
        },
      }
    case PageTypeEnum.ADD_A_GIFT:
      return {
        primaryButton: {
          onClick: buttonActions.addCrossSellGiftToBasket,
          text: localiseProductInfoText(
            product.customisable
              ? 'common.personalise'
              : 'common.add_to_basket',
          ),
        },
      }
    case PageTypeEnum.BASKET:
      return {
        primaryButton: {
          onClick: buttonActions.addBasketCrossSellGiftToBasket,
          text: primaryButtonText,
        },
      }
    case PageTypeEnum.CARD_OPTIONS:
      return {
        primaryButton: {
          onClick: buttonActions.addDigitalGiftCardToBasket,
          text: primaryButtonText,
        },
      }
    default:
      if (canSeeCardOptions) {
        return {
          primaryButton: {
            onClick: buttonActions.showCardOptionsForDigitalGift,
            text: primaryButtonText,
          },
        }
      }

      return {
        primaryButton: {
          onClick:
            productType === 'groupCard' || groupCardProjectId
              ? buttonActions.createGroupCard
              : buttonActions.personalise,
          text: primaryButtonText,
        },
        ...(showSecondaryButton && {
          secondaryButton: {
            onClick: buttonActions.onAddToBasket,
            text: localiseProductInfoText('common.add_to_basket'),
          },
        }),
      }
  }
}
