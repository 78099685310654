import React from 'react'
import { useQuery } from '@moonpig/web-core-graphql'
import { mergeProductWithAddons } from './mergeProductWithAddons'
import {
  AddedToBasketEvent,
  PageTypeEnum,
  ProductInfoProduct,
} from '../../types'
import { useProductWithAddonsQuery } from '../../queries/useProductWithAddonsQuery'
import { ProductInfo } from '../ProductInfo/ProductInfo'
import { useProductRequiresPersonalisationQuery } from '../../queries/useProductRequiresPersonalisationQuery'
import { getGiftUpsellProductId } from '../../utils/giftUpsellProductIds'
import {
  GetUpsellProductQuery,
  GetUpsellProductGQL,
  GetUpsellProductQueryVariables,
} from '../../queries/getUpsellProduct'
import { isCardProduct } from '../../utils/isCardProduct'
import { useDispatchCutOffQuery } from '../../queries/DispatchCutOff'

type WidthProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  leftWidth?: any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rightWidth?: any
}

type ProductInfoProps = WidthProps & {
  product: ProductInfoProduct
  productIndex?: number
  onAddToBasket: (addedToBasketEvent: AddedToBasketEvent) => void
  onVariantChange?: (variantValue: string) => void
  variantsTitle?: string
  variantCategoriesTitle?: string
  variantInfoLink?: string
  variantInfoLinkText?: string
  infoAlertTitle?: string
  infoAlertText?: string
  quantity?: number
  pageLocation: string
  showTitle?: boolean
  showMoreInfo?: boolean
  showProductTabs?: boolean
  hideStickyCta?: boolean
  groupCardProject?: string
  isFromModal?: boolean
  pageType: PageTypeEnum
}

export const ProductInfoWithQuery: React.FC<ProductInfoProps> = props => {
  const { product, pageLocation, isFromModal } = props
  const {
    id: productId,
    category: { department },
  } = product

  const requiresEditing = useProductRequiresPersonalisationQuery({
    productId,
    skip: !isCardProduct(product.category.department),
  })

  const giftUpsellProductId = getGiftUpsellProductId(product.id)

  const { data: upsellProductData } = useQuery<
    GetUpsellProductQuery,
    GetUpsellProductQueryVariables
  >(GetUpsellProductGQL, {
    variables: {
      productId: giftUpsellProductId as string,
    },
    skip: !giftUpsellProductId,
  })

  const addonsData = useProductWithAddonsQuery(productId)

  const productWithAddons = mergeProductWithAddons({
    product,
    addonsData,
  })

  const dispatchCutOff = useDispatchCutOffQuery(department)

  return (
    <ProductInfo
      {...props}
      product={productWithAddons}
      dispatchCutOff={dispatchCutOff}
      pageLocation={pageLocation}
      requiresEditing={requiresEditing}
      addonLoaded={!!addonsData}
      isFromModal={isFromModal}
      upsellProduct={upsellProductData?.productLookup?.products[0]}
    />
  )
}
