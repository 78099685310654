import { Module } from '../../types'

export const createCardUpsellSimplificationNoECardDesktopConfiguration =
  (): Module[] => {
    return [
      {
        __typename: 'ModuleTwoColumn',
        left: [{ __typename: 'ModuleImages' }],
        right: [
          { __typename: 'ModuleMemberPricingHeader' },
          { __typename: 'ModuleFromPrice' },
          { __typename: 'ModuleQuantity' },
          { __typename: 'ModulePromotions' },
          { __typename: 'ModuleDispatch' },
          { __typename: 'ModuleButtons' },
          { __typename: 'ModuleMoreInfo' },
        ],
        ratio: '50/50',
      },
      { __typename: 'ModulePeopleAlsoViewedVariant' },
    ]
  }
