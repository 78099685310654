import { setBrowserCookie } from '@moonpig/web-core-cookies'
import { getGroupToFacetKeyObject } from './getGroupToFacetKeyObject'

export const TWO_DAYS_IN_SECONDS = 172800

export const setUIHCookie = ({
  product,
  facets,
  variantSize,
}: {
  product: {
    id: string
    category: { id: string | number }
    isSponsored?: boolean
    internallyPromoted?: boolean
  }
  facets?: {
    group: string
    facetKey: string
  }[]
  variantSize?: string
}) => {
  setBrowserCookie(
    'UIH',
    JSON.stringify({
      filters: facets
        ? {
            ...getGroupToFacetKeyObject(
              facets.reverse(), // the first selected always takes precedence
            ),
          }
        : {},
      moonpigProductNo: product.id,
      productCategoryId: product.category.id,
      isSponsored: product.isSponsored ? product.isSponsored : false,
      internallyPromoted: product.internallyPromoted
        ? product.internallyPromoted
        : false,
      variantSize,
    }),
    {
      path: '/',
      maxAge: TWO_DAYS_IN_SECONDS,
    },
  )
}
