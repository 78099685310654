import { Variant } from '../../types'
import { STANDARD_FLOWER_SIZE_ID, LARGE_FLOWER_SIZE_ID } from '../../constants'
import { useProductInfoLocaleText } from '../../components/ProductInfo/ProductInfo.locale'

export const getVariantDescription = (
  variant: Pick<Variant, 'key' | 'subtitle' | 'dimensions'>,
  localiseText: ReturnType<typeof useProductInfoLocaleText>,
) => {
  switch (variant.key) {
    case STANDARD_FLOWER_SIZE_ID:
      return localiseText('common.flower_variant_description_standard')
    case LARGE_FLOWER_SIZE_ID:
      return localiseText('common.flower_variant_description_large')
    default:
      return variant.subtitle || variant.dimensions?.description
  }
}
