export const createModalSizeChangeGAEventDetails = (sizeId: string) => {
  return {
    event: 'eventLoaded',
    eventData: {
      name: 'ga_selectSize',
      category: 'product action',
      action: 'select size',
      label: sizeId,
      interaction: 1,
    },
    productData: undefined,
    listData: undefined,
  }
}
