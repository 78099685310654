import React, { FC } from 'react'
import { styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { InfoStrip, Text } from '@moonpig/launchpad-components'
import { IllustrationTruckEnvelope } from '@moonpig/launchpad-assets'
import { ModuleDispatch } from '../types'
import { useProductStore } from '../../contexts/product'
import { useDispatchCutOffTimer } from './useDispatchCutOff'
import { useDispatchCutOffQuery } from '../../queries/DispatchCutOff'

const StyledIcon = styled(IllustrationTruckEnvelope)`
  width: 48px;
  height: 48px;
  min-width: 48px;
  ${s({ mr: 4 })}
`

type Props = {
  module: ModuleDispatch
}

export const DispatchModule: FC<Props> = () => {
  const [{ product }] = useProductStore()
  const dispatchCutOff = useDispatchCutOffQuery(product.category.department)
  const { isVisible, message } = useDispatchCutOffTimer(dispatchCutOff)

  return (
    <>
      {isVisible && (
        <InfoStrip
          mb={6}
          fullWidth
          leftAlignContent
          data-testid="module-dispatch"
        >
          <StyledIcon />
          <Text typography="typeDisplay05">{message}</Text>
        </InfoStrip>
      )}
    </>
  )
}
