import React, { createContext, useContext, FC, useMemo } from 'react'

export type State = {
  variantKey?: string
}

const stateContext = createContext<State>({})

export const useProductInitialVariantSelectionState = () => {
  const context = useContext(stateContext)
  return context
}

/* istanbul ignore next */
export const ProductInitialVariantSelectionProvider: FC<
  React.PropsWithChildren<{
    variantKey?: string
  }>
> = ({ variantKey, children }) => {
  const value = useMemo(() => ({ variantKey }), [variantKey])

  return <stateContext.Provider value={value}>{children}</stateContext.Provider>
}
