import React, { Component, ReactNode } from 'react'
import { logger } from '@moonpig/web-core-monitoring'
import { LogContext } from '@moonpig/common-logging-console'

type Props = {
  children: ReactNode
  type?: string
}

type State = {
  error: Error | null
}

export class ModuleErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = { error: null }
  }

  static getDerivedStateFromError(error: Error): State {
    return { error }
  }

  componentDidCatch(error: Error) {
    const context: LogContext = this.props.type ? { type: this.props.type } : {}
    logger.fixImmediately('Unexpected module error', context, error)
  }

  render(): React.ReactNode {
    const { children } = this.props
    const { error } = this.state

    if (error) {
      return null
    }

    return <>{children}</>
  }
}
