import React, { FC, useCallback } from 'react'
import { useLanguage } from '@moonpig/web-core-stores'
import { Box } from '@moonpig/launchpad-components'
import { getCurrencySymbolFromCode } from '@moonpig/web-core-utils'
import { useProductInfoLocaleText } from '../../components/ProductInfo/ProductInfo.locale'
import type { ModuleVariants, ModuleContext } from '../types'
import { useProductStore } from '../../contexts/product'
import { formatProductVariants } from '../../components/ProductInfo'
import { VariantSelector } from '../../components/VariantSelector/VariantSelector'
import { DropdownWithIcon } from '../../components/DropdownWithIcon'
import { useTracking } from '../../contexts/tracking'
import { getVariantIcon } from '../../utils/getVariantIcon'
import { getVariantSelectorTitle } from '../../utils/getVariantSelectorTitle'
import { getVariantDescription } from '../../utils/getVariantDescription'

type Props = {
  module: ModuleVariants
  context: ModuleContext
}

export const VariantsModule: FC<Props> = ({
  module: { type, unavailableVariants },
}) => {
  const language = useLanguage()
  const localiseText = useProductInfoLocaleText()
  const [{ product, variant }, dispatcher] = useProductStore()
  const { selectProduct } = useTracking()

  const { variants, category } = product

  const handleVariantChange = useCallback(
    (variantKey: string) => {
      const selectedVariant =
        variants.find(v => v.key === variantKey) ||
        /* istanbul ignore next */ variant

      dispatcher.selectVariant(variantKey)
      selectProduct({
        product,
        variant: selectedVariant,
        index: 0,
        component: `size selector ${type}`,
      })
    },
    [variants, variant, dispatcher, selectProduct, product, type],
  )

  const filteredVariants = variants.filter(
    v => !unavailableVariants?.includes(v.key),
  )

  const formattedVariants = formatProductVariants({
    variants: filteredVariants,
    selectedVariant: variant,
    id: product.id,
    language,
    eCardDescription: localiseText('common.sent_instantly_via_email'),
  })

  const dropdownItems = formattedVariants.map(v => ({
    value: v.key,
    title: v.title,
    icon: getVariantIcon({ sizeId: v.key, isLandscape: !!product.isLandscape }),
    subtitle: `${v.price.currencySymbol}${v.price.displayPrice}`,
  }))

  return (
    <Box data-testid="module-variants" mb={6}>
      {type === 'dropdown' ? (
        <DropdownWithIcon
          title={localiseText('common.size')}
          selectedValue={variant.key}
          items={dropdownItems}
          onChange={handleVariantChange}
        />
      ) : (
        <VariantSelector
          title={getVariantSelectorTitle(category.department, localiseText)}
          isLandscape={!!product.isLandscape}
          selectedValue={variant.key}
          items={formattedVariants.map(formattedVariant => {
            return {
              ...formattedVariant,
              description: getVariantDescription(
                formattedVariant,
                localiseText,
              ),
              price: `${formattedVariant.price.currencySymbol}${formattedVariant.price.displayPrice}`,
              absolutePrice: `${getCurrencySymbolFromCode(
                formattedVariant.fullPrice.currencyCode,
              )}${formattedVariant.fullPrice.itemPrice}`,
            }
          })}
          onChange={handleVariantChange}
        />
      )}
    </Box>
  )
}
