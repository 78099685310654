import { useRouter } from '@moonpig/web-core-routing'
import { useStoreId } from '@moonpig/web-core-stores'
import { match, useIsMobile } from '@moonpig/web-shared-utils'
import { useExperiment } from '@moonpig/web-core-experiments'
import { useProductsLocaleText } from '../../locale'
import { ProductInfoProduct } from '../../types'
import { isCardProduct } from '../../utils/isCardProduct'
import { Module } from '../types'
import { createGroupCardProjectDesktopConfiguration } from './group-project/GroupCardProjectDesktop'
import { createGroupCardProjectMobileConfiguration } from './group-project/GroupCardProjectMobile'
import { createCardMultiBuyDesktopConfiguration } from './multibuy/CardMultiBuyDesktop'
import { createCardMultiBuyMobileConfiguration } from './multibuy/CardMultiBuyMobile'
import { createCardOutOfStockDesktopConfiguration } from './out-of-stock/CardOutOfStockDesktop'
import { createCardOutOfStockMobileConfiguration } from './out-of-stock/CardOutOfStockMobile'
import { createCardUpsellSimplificationMobileConfiguration } from './upsell-simplification/CardUpsellSimplificationMobile'
import { createCardUpsellSimplificationDesktopConfiguration } from './upsell-simplification/CardUpsellSimplificationDesktop'
import { E_CARD_SIZE_ID } from '../../constants'
import { createCardUpsellSimplificationNoECardDesktopConfiguration } from './upsell-simplification/CardUpsellSimplificationNoECardDesktop'
import { createCardUpsellSimplificationNoECardMobileConfiguration } from './upsell-simplification/CardUpsellSimplificationNoECardMobile'

type UseDefaultModuleConfiguration = (context: {
  flags: Record<string, boolean>
}) => (input: { product: ProductInfoProduct }) => Module[]

export const useDefaultModuleConfiguration: UseDefaultModuleConfiguration =
  () => {
    const storeId = useStoreId()
    const localise = useProductsLocaleText()
    const isMobile = useIsMobile()
    const router = useRouter()
    const isUpsellSimplificationEnabled =
      useExperiment('explore-upsell-simplification')?.toLowerCase() ===
      'enabled'

    return ({ product }) => {
      const isGroupCardProject =
        !!router.getCurrentRoute<'search'>()?.params?.groupCardProject

      const conditions = {
        isCard: isCardProduct(product.category.department),
        inStock: product.variants.some(({ inStock }) => inStock),
        isMobile,
        isGroupCardProject,
        storeId,
        isUpsellSimplificationEnabled,
        hasECard: !!product.variants.find(({ key }) => key === E_CARD_SIZE_ID),
      }

      return match(conditions)
        .with(
          { isCard: false }, // prettier-ignore
          () => [], // prettier-ignore
        )
        .with(
          { inStock: false, isMobile: true }, // prettier-ignore
          () => createCardOutOfStockMobileConfiguration(), // prettier-ignore
        )
        .with(
          { inStock: false, isMobile: false }, // prettier-ignore
          () => createCardOutOfStockDesktopConfiguration(), // prettier-ignore
        )
        .with(
          { isGroupCardProject: true, isMobile: true,  }, // prettier-ignore
          () => createGroupCardProjectMobileConfiguration(), // prettier-ignore
        )
        .with(
          { isGroupCardProject: true, isMobile: false, }, // prettier-ignore
          () => createGroupCardProjectDesktopConfiguration(), // prettier-ignore
        )
        .with(
          { isMobile: true, isUpsellSimplificationEnabled: true, hasECard: true }, // prettier-ignore
          () => createCardUpsellSimplificationMobileConfiguration(), // prettier-ignore
        )
        .with(
          { isMobile: false, isUpsellSimplificationEnabled: true, hasECard: true }, // prettier-ignore
          () => createCardUpsellSimplificationDesktopConfiguration(), // prettier-ignore
        )
        .with(
          { isMobile: true, isUpsellSimplificationEnabled: true,  }, // prettier-ignore
          () => createCardUpsellSimplificationNoECardMobileConfiguration(), // prettier-ignore
        )
        .with(
          { isMobile: false, isUpsellSimplificationEnabled: true, }, // prettier-ignore
          () => createCardUpsellSimplificationNoECardDesktopConfiguration(), // prettier-ignore
        )
        .with(
          { isMobile: true }, // prettier-ignore
          () => createCardMultiBuyMobileConfiguration({ localise, storeId }), // prettier-ignore
        )
        .with(
          { isMobile: false }, // prettier-ignore
          () => createCardMultiBuyDesktopConfiguration({ localise, storeId }), // prettier-ignore
        )
        .otherwise(/* istanbul ignore next */ () => [])
    }
  }
