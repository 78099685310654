import React, { FC, MouseEventHandler } from 'react'
import {
  styled,
  breakpoint,
  breakpointDown,
  screenReaderOnly,
} from '@moonpig/launchpad-utils'
import {
  system as s,
  sizingSpacing,
  SizingSpacingSystemProps,
} from '@moonpig/launchpad-system'
import { TertiaryButton, Text } from '@moonpig/launchpad-components'

import {
  IconSystemChevronLeft,
  IconSystemChevronRight,
} from '@moonpig/launchpad-assets'
import { spacingPx } from '@moonpig/launchpad-theme'
import { useLocaleText } from '../locale'

type Breadcrumb = {
  url: string
  label: React.ReactNode
}

type BreadcrumbsProps = {
  items: Breadcrumb[]
  onClick?: (params: {
    url: string
    depth: number
    label: React.ReactNode
  }) => Promise<void>
} & SizingSpacingSystemProps

const BREAKPOINT = 'lg'

const StyledBreadcrumbs = styled.nav`
  ${sizingSpacing}
`

const StyledBreadcrumb = styled.li<{
  parentPage: boolean
  currentPage: boolean
}>`
  ${breakpointDown(BREAKPOINT)} {
    ${({ parentPage }) => (parentPage ? '' : screenReaderOnly)};
  }

  ${breakpoint(BREAKPOINT)} {
    display: inline-block;
  }
`

const StyledTertiaryButtonLeft = styled(TertiaryButton)`
  text-align: left;
  ${s({ py: 4 })}
  ${breakpoint(BREAKPOINT)} {
    display: none;
  }
`

const StyledList = styled.ol`
  display: flex;
  flex-direction: row;
  gap: ${spacingPx(4)};
  align-items: center;
`

const StyledTertiaryButtonRight = styled(TertiaryButton)`
  text-align: left;
  ${s({ py: 4 })}
  ${breakpointDown(BREAKPOINT)} {
    display: none;
  }
`

const StyledCurrentPage = styled(Text)`
  ${breakpointDown(BREAKPOINT)} {
    display: none;
  }
`

export const Breadcrumbs: FC<React.PropsWithChildren<BreadcrumbsProps>> = ({
  items,
  onClick,
  ...props
}) => {
  const t = useLocaleText()

  return (
    <StyledBreadcrumbs aria-label={t('breadcrumbs.breadcrumbs')} {...props}>
      <StyledList role="list">
        {items.map(({ url, label }, index) => {
          const parentPage = items.length === index + 2
          const currentPage = items.length === index + 1

          const onClickHandler: MouseEventHandler<HTMLElement> = async evt => {
            if (onClick) {
              evt.preventDefault()
              await onClick({ url, depth: index, label })
              window.location.href = url
            }
          }

          return (
            <StyledBreadcrumb
              key={url}
              parentPage={parentPage}
              currentPage={currentPage}
            >
              {parentPage && (
                <StyledTertiaryButtonLeft
                  href={url}
                  leadingIcon={<IconSystemChevronLeft />}
                  aria-hidden
                  onClick={onClickHandler}
                >
                  {label}
                </StyledTertiaryButtonLeft>
              )}
              {currentPage ? (
                <StyledCurrentPage aria-current="page">
                  {label}
                </StyledCurrentPage>
              ) : (
                <StyledTertiaryButtonRight
                  href={url}
                  trailingIcon={<IconSystemChevronRight />}
                  onClick={onClickHandler}
                >
                  {label}
                </StyledTertiaryButtonRight>
              )}
            </StyledBreadcrumb>
          )
        })}
      </StyledList>
    </StyledBreadcrumbs>
  )
}
