import React, { FC } from 'react'
import { system as s } from '@moonpig/launchpad-system'
import { styled } from '@moonpig/launchpad-utils'
import {
  Menu as DropdownMenu,
  MenuItems as DropdownMenuItems,
  MenuPopover as DropdownMenuPopover,
} from '@reach/menu-button'
import { Box, Flex, Text } from '@moonpig/launchpad-components'
import { positionMatchWidth } from '@reach/popover'
import { DropdownMenuButton } from './DropdownMenuButton'
import { DROPDOWN_ITEM_HEIGHT, DROPDOWN_ITEM_BORDER_RADIUS } from './constants'
import { DropdownMenuItem } from './DropdownMenuItem'

const StyledDropdownMenuPopover = styled(DropdownMenuPopover)`
  position: relative;
  top: -90px;
  display: block;
  box-sizing: border-box;
  overflow: hidden;
  outline: none;
  z-index: 99;
  border-radius: ${DROPDOWN_ITEM_BORDER_RADIUS};
  ${s({
    bgcolor: 'colorBackground01',
    border: 1,
    borderColor: 'colorBorder04',
    boxShadow: 2,
  })}

  [data-reach-menu-items] {
    outline: none;
  }

  &[hidden] {
    display: none;
  }
`

const StyledTitle = styled(Text)`
  flex: 1;
  display: flex;
  align-items: center;
  ${s({
    mb: 0,
    color: 'colorTextHeadline',
    typography: { xs: 'typeMobileDisplay03', md: 'typeDesktopDisplay05' },
  })}
`

export const DropdownWithIconExperiment: FC<{
  title?: string
  selectedValue: string
  items: {
    value: string
    heading: string
    title?: string
    subtitle?: string
    icon?: JSX.Element
  }[]
  onChange: (value: string) => void
}> = ({ title, selectedValue, items, onChange }) => {
  const selectedItem =
    items.find(item => item.value === selectedValue) ||
    /* istanbul ignore next */ items[0]

  return (
    <>
      <Flex
        height={DROPDOWN_ITEM_HEIGHT}
        data-testid="shared-products-dropdown-experiment"
      >
        {title && (
          <StyledTitle as="p" data-testid="shared-products-dropdown-title">
            {title}
          </StyledTitle>
        )}
        <Box flex={5}>
          <DropdownMenu>
            {({ isExpanded }: { isExpanded: boolean }) => (
              <>
                <DropdownMenuButton
                  item={selectedItem}
                  isExpanded={isExpanded}
                />
                <StyledDropdownMenuPopover
                  position={positionMatchWidth}
                  portal={false}
                >
                  <DropdownMenuItems>
                    {items.map(item => (
                      <DropdownMenuItem
                        key={item.value}
                        item={item}
                        isSelected={item.value === selectedItem.value}
                        onSelectHandler={onChange}
                      />
                    ))}
                  </DropdownMenuItems>
                </StyledDropdownMenuPopover>
              </>
            )}
          </DropdownMenu>
        </Box>
      </Flex>
    </>
  )
}
