import React, { FC } from 'react'
import { ModuleContext, Module, ModuleMultiBuyTabs, ModuleTabs } from '../types'
import { useProductStore } from '../../contexts/product'
import { useTracking } from '../../contexts/tracking'
import { TabsModule } from '../Tabs'

const MULTI_BUY_TAB_DEFAULT_QUANTITY = 5

type Props = {
  tabModule: ModuleMultiBuyTabs
  context: ModuleContext
  mapModule: (module: Module, props: ModuleContext) => JSX.Element | null
}

export const MultiBuyTabsModule: FC<Props> = ({
  tabModule,
  context,
  mapModule,
}) => {
  const [_, productStoreDispatcher] = useProductStore()
  const { selectContent } = useTracking()

  const handleOnChanged = (tab: { title: string }, index: number) => {
    productStoreDispatcher.reset()

    if (index === 1) {
      productStoreDispatcher.selectQuantity(MULTI_BUY_TAB_DEFAULT_QUANTITY)
    }

    selectContent({
      action: tabModule.meta.intent,
      component: 'tab',
      label: tab.title,
    })
  }

  return (
    <TabsModule
      context={context}
      module={tabModule as unknown as ModuleTabs}
      mapModule={mapModule}
      onChanged={handleOnChanged}
      data-testid="module-multi-buy-tabs"
    />
  )
}
