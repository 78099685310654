import React from 'react'
import {
  LocaleTextHook,
  LocaleTextDictionary,
} from '@moonpig/web-core-locale-text'
import { DeliveryInformation } from '../../components/ProductTabs/DeliveryInformation'
import { ImportedHTML } from '../../components/ProductTabs/ImportedHTML'
import { ProductReviews } from '../../components/ProductTabs/ProductReviews'
import { AccordionSection, ProductInfoProduct } from '../../types'

type AccordionSectionContent = {
  title: string
  sectionType: AccordionSection
  render: JSX.Element
}

type AccordionSectionsArgs = {
  product: ProductInfoProduct
  localiseText: LocaleTextHook<LocaleTextDictionary>
}

export const getAccordionSections = ({
  product,
  localiseText,
}: AccordionSectionsArgs): AccordionSectionContent[] => {
  const shouldIncludeReviews =
    product.rating && product.rating?.count && product.rating.count > 0
  return [
    {
      title: localiseText('common.product_details'),
      sectionType: AccordionSection.DESCRIPTION,
      render: <ImportedHTML html={product.description || ''} />,
    },
    {
      title: localiseText('common.delivery_options'),
      sectionType: AccordionSection.DELIVERY,
      render: <DeliveryInformation department={product.category.department} />,
    },
    ...(shouldIncludeReviews
      ? [
          {
            title: localiseText('common.reviews'),
            sectionType: AccordionSection.REVIEWS,
            render: <ProductReviews id={product.id} />,
          },
        ]
      : []),
  ]
}
