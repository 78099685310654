import React, { FC } from 'react'
import { styled } from '@moonpig/launchpad-utils'
import { Flex } from '@moonpig/launchpad-components'
import { ModuleContext, ModuleLink } from '../types'
import { useTracking } from '../../contexts/tracking'

const StyledLink = styled.a`
  display: flex;
  min-height: 48px;
  font-weight: bold;
  align-items: center;
`

type Props = {
  context: ModuleContext
  module: ModuleLink
}

export const LinkModule: FC<Props> = ({
  module: {
    href,
    title,
    meta: { intent },
  },
}) => {
  const { selectContent } = useTracking()

  return (
    <Flex justifyContent="center" data-testid="module-link">
      <StyledLink
        href={href}
        onClick={() =>
          selectContent({
            action: intent,
            component: 'hyperlink',
            label: title,
          })
        }
      >
        {title}
      </StyledLink>
    </Flex>
  )
}
