import { gql } from '@moonpig/web-core-graphql'
import { ADDONS_FIELDS_FRAGMENT } from '../useProductWithAddonsQuery'

export const GetPeopleAlsoViewedGQL = gql`
  ${ADDONS_FIELDS_FRAGMENT}

  query GetPeopleAlsoViewed($productId: String!) {
    productLookup(productIds: [$productId]) {
      products {
        peopleAlsoViewed: recommendations(algorithmId: "CustomersAlsoViewed") {
          products {
            id
            title
            description
            variants {
              key
              title
              subtitle
              price {
                currencyCode
                centAmount
                fractionDigits
              }
              fullPrice {
                centAmount
                currencyCode
                fractionDigits
              }
              discountedPercentage
              inStock
              minimumQuantity
              sku
              addons(clientUsesDescription: true) {
                ...AddonsFields
              }
              bundles {
                description
                price {
                  currencyCode
                  centAmount
                  fractionDigits
                }
                discountPercentage
                size
              }
              capabilities {
                video
              }
            }
            category {
              id
              name
              slug
              department
            }
            customisable
            isLandscape
            masterVariant {
              key
              title
              subtitle
              price {
                centAmount
                currencyCode
                fractionDigits
              }
              fullPrice {
                centAmount
                currencyCode
                fractionDigits
              }
              discountedPercentage
              minimumQuantity
              images {
                url
              }
              masterImage {
                url
              }
              addons(clientUsesDescription: true) {
                ...AddonsFields
              }
              inStock
              sku
              bundles {
                description
                price {
                  currencyCode
                  centAmount
                  fractionDigits
                }
                discountPercentage
                size
              }
              capabilities {
                video
              }
            }
            rating {
              count
              score
            }
            slug
            isSponsored
            clickRankDocumentCount
          }
        }
      }
    }
  }
`
