import { ProductInfoProduct } from '../../types'
import {
  GIANT_SQUARE_CARD_SIZE_ID,
  LARGE_SQUARE_CARD_SIZE_ID,
  POSTCARD_SIZE_ID,
  SQUARE_CARD_SIZE_ID,
} from '../../constants'
import {
  FORMAT_LANDSCAPE_CARD,
  FORMAT_PORTRAIT_CARD,
  FORMAT_POST_CARD,
  FORMAT_SQUARE_CARD,
} from './constants'

export const getCardFormat = (product: ProductInfoProduct): string => {
  if (product.masterVariant.key === POSTCARD_SIZE_ID) return FORMAT_POST_CARD

  if (
    [
      SQUARE_CARD_SIZE_ID,
      LARGE_SQUARE_CARD_SIZE_ID,
      GIANT_SQUARE_CARD_SIZE_ID,
    ].includes(product.masterVariant.key)
  )
    return FORMAT_SQUARE_CARD

  return product.isLandscape ? FORMAT_LANDSCAPE_CARD : FORMAT_PORTRAIT_CARD
}
