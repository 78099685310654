import React, { FC } from 'react'
import { Box, Flex, Text } from '@moonpig/launchpad-components'
import { styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { IllustrationDiscount } from '@moonpig/launchpad-assets'
import { useStoreId } from '@moonpig/web-core-stores'
import { MULTI_CARD_DISCOUNTS } from '@moonpig/web-core-brand/config'
import { Region } from '@moonpig/web-core-types'
import { ModuleContext, ModulePromotions } from '../types'
import { PromotionRow } from './PromotionRow'
import { useProductsLocaleText } from '../../locale'

const DISCOUNT_ICON_SIZE = 48

const StyledBox = styled(Box)`
  text-align: initial;
  overflow: hidden;

  ${s({
    my: 6,
    borderRadius: 2,
    border: 1,
    borderColor: 'colorBackgroundInformation',
    bgcolor: 'colorBackgroundInformation',
  })}
`

const StyledTableWrapper = styled.div`
  ${s({ px: 5, bgcolor: 'colorBackground01' })}
`

const getPromotionRows = (storeId: Region) =>
  MULTI_CARD_DISCOUNTS[storeId].map(props => (
    <PromotionRow key={props.discount} {...props} />
  ))

type Props = {
  context: ModuleContext
  module: ModulePromotions
}

export const PromotionsModule: FC<Props> = () => {
  const localiseText = useProductsLocaleText()
  const storeId = useStoreId()
  const promotions = getPromotionRows(storeId)

  return promotions.length ? (
    <StyledBox data-testid="module-promotions">
      <Flex alignItems="center" p={6}>
        <Box flexShrink={0}>
          <IllustrationDiscount
            width={DISCOUNT_ICON_SIZE}
            height={DISCOUNT_ICON_SIZE}
          />
        </Box>
        <Text as="h2" ml={5} mb={0}>
          {localiseText(
            'multicard.multi_buy_discounts_apply_to_all_cards_in_your_basket',
          )}
        </Text>
      </Flex>
      <StyledTableWrapper>{promotions}</StyledTableWrapper>
    </StyledBox>
  ) : null
}
