import React, { FC, useEffect } from 'react'
import { Box } from '@moonpig/launchpad-components'
import { useCoreLocaleText } from '@moonpig/web-core-locale-text'
import { styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { ModuleContext, ModulePeopleAlsoViewed } from '../types'
import { useProductStore } from '../../contexts/product'
import { usePeopleAlsoViewedQuery } from '../../queries/PeopleAlsoViewed'
import { Recommendations } from '../../components/Recommendations'
import { useTracking } from '../../contexts/tracking'

const StyledBox = styled(Box)`
  ${s({
    mb: -5,
    mx: -5,
    pt: 5,
    px: 5,
    pb: 8,
    bgcolor: {
      xs: 'colorBackground03',
      md: 'transparent',
    },
  })}
`

type Props = {
  module: ModulePeopleAlsoViewed
  context: ModuleContext
}

export const PeopleAlsoViewedModule: FC<Props> = () => {
  const [{ initialProduct }, dispatcher] = useProductStore()
  const products = usePeopleAlsoViewedQuery({ product: initialProduct })
  const localiseText = useCoreLocaleText()
  const tracking = useTracking()
  const moduleTitle = localiseText('common.people_also_viewed')

  const handleClick = (index: number) => {
    const product = products[index]

    dispatcher.setProduct(product)
    tracking.selectProduct({
      product,
      list: {
        name: moduleTitle,
        index: 0,
        totalNumberOfLists: 1,
        length: products.length,
      },
      variant: product.masterVariant,
      index,
      component: 'carousel',
    })
  }

  useEffect(() => {
    if (products.length) {
      tracking.viewProductList({
        products,
        listName: moduleTitle,
        component: 'carousel',
      })
    }
  }, [moduleTitle, products, tracking])

  return products.length ? (
    <StyledBox data-testid="module-people-also-viewed">
      <Recommendations
        recommendations={products.map(
          ({ id, title, masterVariant, category }) => ({
            id,
            title,
            url: masterVariant.masterImage.url,
            category,
            masterVariant,
          }),
        )}
        onClick={handleClick}
      />
    </StyledBox>
  ) : null
}
