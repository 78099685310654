import { gql, useQuery } from '@moonpig/web-core-graphql'
import {
  GetProductPersonalisationQuery,
  GetProductPersonalisationQueryVariables,
} from './__generated__/useProductRequiresPersonalisationQuery'
import { RequiresEditing } from '../../types'
import { DEFAULT_REQUIRES_EDITING } from '../../constants'
import { getHandlerForProductPersonalisationApiError } from './getHandlerForProductPersonalisationApiError'

export const GetProductPersonalisationGQL = gql`
  query GetProductPersonalisation($productIds: [String]) {
    productLookup(productIds: $productIds) {
      products {
        personalisation {
          __typename
          ... on ProductPersonalisation {
            requiresEditing
          }
          ... on ProductNotPersonalisableError {
            message
          }
          ... on Error {
            message
          }
        }
      }
    }
  }
`

export const useProductRequiresPersonalisationQuery = ({
  productId,
  skip,
}: {
  productId: string
  skip?: boolean
}): RequiresEditing => {
  const { data, loading } = useQuery<
    GetProductPersonalisationQuery,
    GetProductPersonalisationQueryVariables
  >(GetProductPersonalisationGQL, {
    variables: {
      productIds: [productId],
    },
    skip,
    onError: getHandlerForProductPersonalisationApiError(productId),
  })
  return {
    loading,
    value:
      data?.productLookup?.products[0]?.personalisation?.__typename ===
      'ProductPersonalisation'
        ? /* istanbul ignore next */ data?.productLookup?.products[0]
            ?.personalisation.requiresEditing
        : DEFAULT_REQUIRES_EDITING,
  }
}
