import { LocaleTextHook } from '@moonpig/web-core-locale-text'
import { DepartmentsEnum } from '@moonpig/web-shared-types-graphql/graphqlTypes'
import { ProductInfoProduct } from '../../types'
import { CanSeeCardOptionsResult } from '../../utils/useDigitalGiftingCardOptions/useDigitalGiftingCardOptions'
import { PRODUCT_INFO_TEXT } from '../../components/ProductInfo/ProductInfo.locale'

type Args = {
  product: ProductInfoProduct
  productType: string | undefined
  groupCardProjectId: string | undefined
  localiseText: LocaleTextHook<typeof PRODUCT_INFO_TEXT>
  getCanSeeCardOptions: (department: DepartmentsEnum) => CanSeeCardOptionsResult
}

export const getPrimaryButtonText = ({
  product,
  productType,
  groupCardProjectId,
  localiseText,
  getCanSeeCardOptions,
}: Args): string => {
  const isCustomisable =
    product.customisable ||
    product.category.department === DepartmentsEnum.DIGITAL_GIFTS
  const { canSeeCardOptions, digitalGiftCtaToken } = getCanSeeCardOptions(
    product.category.department,
  )

  if (canSeeCardOptions) {
    return localiseText(digitalGiftCtaToken)
  }

  if (isCustomisable) {
    if (productType === 'groupCard') {
      return localiseText('group_card.make_group_card')
    }
    return groupCardProjectId
      ? localiseText('common.next')
      : localiseText('common.personalise')
  }

  return localiseText('common.add_to_basket')
}
