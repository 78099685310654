import { useStore } from '@moonpig/web-core-stores'
import { getParentDepartment } from '@moonpig/web-core-utils'
import {
  Region as GraphQLRegion,
  DepartmentsEnum,
} from '@moonpig/web-shared-types-graphql/graphqlTypes'
import { useQuery } from '@moonpig/web-core-graphql'
import { DispatchCutOffGQL } from './DispatchCutOffGQL'
import {
  DispatchCutOffQuery,
  DispatchCutOffQueryVariables,
} from './__generated__/DispatchCutOffGQL'
import { DispatchCutOff } from '../../types'
import {
  DispatchCutOffQuery_contentDepartments as ParentContentDepartment,
  DispatchCutOffQuery_contentDepartments_subdepartments as ContentDepartment,
} from './types-graphql'

const departmentDispatchCutOff = ({
  contentDepartments,
  department,
  parentDepartment,
}: {
  contentDepartments: ParentContentDepartment[]
  department: DepartmentsEnum
  parentDepartment: DepartmentsEnum
}) => {
  const departmentsMap = contentDepartments
    .reduce<ContentDepartment[]>((allDepartments, parent) => {
      /* istanbul ignore next */
      return allDepartments.concat([parent, ...(parent.subdepartments || [])])
    }, [])
    .reduce<{ [key: string]: ContentDepartment }>((currentMap, current) => {
      return {
        ...currentMap,
        [current.id]: current,
      }
    }, {})

  return departmentsMap[department]?.dispatchCutOff.weekdays.message ||
    departmentsMap[department]?.dispatchCutOff.weekendsAndBankHolidays.message
    ? departmentsMap[department]?.dispatchCutOff
    : departmentsMap[parentDepartment]?.dispatchCutOff
}

export const useDispatchCutOffQuery = (
  department: DepartmentsEnum,
): DispatchCutOff | null => {
  const { store } = useStore()
  const parentDepartment = getParentDepartment(department) as DepartmentsEnum
  const { data } = useQuery<DispatchCutOffQuery, DispatchCutOffQueryVariables>(
    DispatchCutOffGQL,
    {
      variables: {
        filter: [department, parentDepartment],
        region: store.id as GraphQLRegion,
        preview: undefined,
      },
    },
  )

  if (!data) {
    return null
  }

  return departmentDispatchCutOff({
    contentDepartments: data.contentDepartments || [],
    department,
    parentDepartment,
  })
}
