/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useCallback, JSX } from 'react'
import { Box, Text } from '@moonpig/launchpad-components'
import { formatPrice } from '@moonpig/web-core-utils'
import { StyledButton, StyledFlex } from './VariantTabs.styled'
import { useProductStore } from '../../contexts/product'
import { E_CARD_SIZE_ID } from '../../constants'
import { ProductInfoProduct, Variant } from '../../types'
import { useProductsLocaleText } from '../../locale'
import type { Module, ModuleContext, ModuleVariantTabs } from '../types'
import { useTracking } from '../../contexts/tracking'

type Func<TOut, TIn> = (args: TIn) => TOut

type Props = {
  module: ModuleVariantTabs
  mapModule: (module: Module, props: ModuleContext) => JSX.Element | null
  context: ModuleContext
}

const findCheapestVariant: Func<Variant, ProductInfoProduct> = product =>
  product.variants
    .filter(v => v.key !== E_CARD_SIZE_ID)
    .sort((a, b) => a.fullPrice.centAmount - b.fullPrice.centAmount)[0]

const findECardVariant: Func<Variant, ProductInfoProduct> = product =>
  product.variants.find(v => v.key === E_CARD_SIZE_ID)!

const formatVariantPrice: Func<string, Variant> = variant =>
  formatPrice(
    variant.price.centAmount,
    variant.price.fractionDigits,
    variant.price.currencyCode,
  )
export const VariantTabsModule: Func<JSX.Element, Props> = ({
  module,
  mapModule,
  context,
}) => {
  const localise = useProductsLocaleText()
  const [{ product, variant, initialVariant }, { selectVariant }] =
    useProductStore()
  const tracking = useTracking()

  const cardVariant = findCheapestVariant(product)
  const eCardVariant = findECardVariant(product)

  const handleCardClick = useCallback(() => {
    if (eCardVariant.key === variant.key) {
      selectVariant(initialVariant.key)
      tracking.selectProduct({
        product,
        variant: initialVariant,
        index: 0,
        component: `size selector tab`,
      })
    }
  }, [
    eCardVariant.key,
    variant.key,
    selectVariant,
    initialVariant,
    tracking,
    product,
  ])

  const handleECardClick = useCallback(() => {
    if (eCardVariant.key !== variant.key) {
      selectVariant(eCardVariant.key)
      tracking.selectProduct({
        product,
        variant: eCardVariant,
        index: 0,
        component: `size selector tab`,
      })
    }
  }, [eCardVariant, variant.key, selectVariant, tracking, product])

  return (
    <Box pb={6}>
      <StyledFlex data-testid="module-variant-tabs" gap={2}>
        <StyledButton
          type="button"
          className={variant.key !== E_CARD_SIZE_ID ? 'is-selected' : ''}
          onClick={handleCardClick}
        >
          <Text
            typography={{
              xs: 'typeMobileDisplay05',
              md: 'typeDesktopDisplay06',
            }}
            as="div"
          >
            {localise('variant_tabs.card')}
          </Text>
          <Text
            typography={{
              xs: 'typeMobileDisplay06',
              md: 'typeBodyLabel',
            }}
            as="div"
          >
            {localise(
              'variant_tabs.from_price',
              formatVariantPrice(cardVariant),
            )}
          </Text>
        </StyledButton>
        <StyledButton
          type="button"
          className={variant.key === E_CARD_SIZE_ID ? 'is-selected' : ''}
          onClick={handleECardClick}
        >
          <Text
            typography={{
              xs: 'typeMobileDisplay05',
              md: 'typeDesktopDisplay06',
            }}
            as="div"
          >
            {localise('variant_tabs.ecard')}
          </Text>
          <Text
            typography={{
              xs: 'typeMobileDisplay06',
              md: 'typeBodyLabel',
            }}
            as="div"
          >
            {formatVariantPrice(eCardVariant)}
          </Text>
        </StyledButton>
      </StyledFlex>
      <Box>
        {variant.key !== E_CARD_SIZE_ID &&
          module.modules.map((m, key) =>
            mapModule(m as Module, { ...context, key }),
          )}
      </Box>
    </Box>
  )
}
