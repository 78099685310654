export const getGroupToFacetKeyObject = (
  collection: {
    facetKey: string
    group: string
  }[],
): {
  [key: string]: string
} => {
  return collection
    .filter(facet => facet.group)
    .reduce<{ [key: string]: string }>((aggregate, current) => {
      return {
        ...aggregate,
        [current.group && current.group.replace(/preset-/g, '')]:
          current.facetKey,
      }
    }, {})
}
