import React, { FC, useCallback } from 'react'
import { useLanguage } from '@moonpig/web-core-stores'
import { Box } from '@moonpig/launchpad-components'
import { useProductInfoLocaleText } from '../../components/ProductInfo/ProductInfo.locale'
import type { ModuleVariantsExperiment, ModuleContext } from '../types'
import { useProductStore } from '../../contexts/product'
import { formatProductVariants } from '../../components/ProductInfo'
import { DropdownWithIconExperiment } from '../../components/DropdownWithIconExperiment'
import { useTracking } from '../../contexts/tracking'
import { getVariantIcon } from '../../utils/getVariantIcon'

type Props = {
  module: ModuleVariantsExperiment
  context: ModuleContext
}

export const VariantsExperimentModule: FC<Props> = ({
  module: { unavailableVariants },
}) => {
  const language = useLanguage()
  const localiseText = useProductInfoLocaleText()
  const [{ product, variant }, dispatcher] = useProductStore()
  const { selectProduct } = useTracking()

  const { variants } = product

  const handleVariantChange = useCallback(
    (variantKey: string) => {
      const selectedVariant =
        variants.find(v => v.key === variantKey) ||
        /* istanbul ignore next */ variant

      dispatcher.selectVariant(variantKey)
      selectProduct({
        product,
        variant: selectedVariant,
        index: 0,
        component: 'size selector dropdown',
      })
    },
    [variants, variant, dispatcher, selectProduct, product],
  )

  const filteredVariants = variants.filter(
    v => !unavailableVariants?.includes(v.key),
  )

  const formattedVariants = formatProductVariants({
    variants: filteredVariants,
    selectedVariant: variant,
    id: product.id,
    language,
    eCardDescription: localiseText('common.sent_instantly_via_email'),
  })

  const dropdownItems = formattedVariants.map(v => ({
    value: v.key,
    heading: v.title,
    icon: getVariantIcon({ sizeId: v.key, isLandscape: !!product.isLandscape }),
    title: v.description,
    subtitle: `${v.price.currencySymbol}${v.price.displayPrice}`,
  }))

  return (
    <Box data-testid="module-variants-experiment" mb={6}>
      <DropdownWithIconExperiment
        title={localiseText('common.size')}
        selectedValue={variant.key}
        items={dropdownItems}
        onChange={handleVariantChange}
      />
    </Box>
  )
}
