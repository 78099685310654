import {
  trackUIEvent,
  createCustomisationCompletedEvent,
  createProductSelectedEvent,
  TrackingEvent,
  trackGAEvent,
} from '@moonpig/web-core-analytics'
import { getBrowserCookies } from '@moonpig/web-core-cookies'
import { Region } from '@moonpig/web-core-types'
import { getDeviceType } from '@moonpig/web-shared-utils'
import { logger } from '@moonpig/web-core-monitoring'
import {
  AddToBasketAndSplitMutation,
  AddToBasketMutation,
  AddToBasketResult,
} from './types'
import {
  ButtonActionName,
  ProductInfoProduct,
  ProductList,
  Variant,
  GiftXSellTrackingData,
} from '../../types'
import {
  getProductSelectedEventData,
  getQueryStringForEditorLocation,
  createAddToBasketWithAddonGAEvent,
  getGAEventLabels,
} from '../../analytics/createBasketEvents'
import {
  AddCardToBasketAndAssignDesignProps,
  AddCardToBasketAndAssignDesignResponse,
} from '../addDigitalGiftToBasket/useAddCardToBasketAndAssignDesign'
import {
  CreateEmptyDesignProps,
  CreateEmptyDesignResponse,
} from '../addDigitalGiftToBasket/useCreateEmptyDesign'
import { handleMutation } from './handleMutation'
import { DEFAULT_REQUIRES_EDITING } from '../../constants'
import { createPersonaliseGAEvent } from '../../analytics'
import { AddonsFieldsFragment } from '../../queries/useProductWithAddonsQuery'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type EventObject = { [key: string]: any }

type AddToBasketParams = {
  region: Region
  quantity: number
  product: ProductInfoProduct
  variant: Variant
  soldOutMessage: string
  itsNotYouMessage: string
  addonSku?: string
  customerId?: string
  buttonActionName?: ButtonActionName
  filters?: { group: string; facetKey: string }[]
  experiments?: { [key: string]: string } | undefined
  searchTerm?: string
  groupCardProject?: string
  productList?: ProductList
  selectedAddon?: AddonsFieldsFragment | null
  itemIndex?: number
  productsLength?: number
  pageLocation?: string
  componentName?: string
  trackPersonaliseEvent?: boolean
  addCardToBasketAndAssignDesign: (
    props: AddCardToBasketAndAssignDesignProps,
  ) => AddCardToBasketAndAssignDesignResponse
  createEmptyDesign: (
    props: CreateEmptyDesignProps,
  ) => CreateEmptyDesignResponse
  addToBasketMutation: AddToBasketMutation | AddToBasketAndSplitMutation
  requiresEditingValue?: boolean
  giftXSellTrackingData?: GiftXSellTrackingData
  onTackingInfo?: (args: { product: ProductInfoProduct }) => {
    label: string
    listName: string
    itemIndex: number
  }
}

const gracefullyTrackGAEvent = async (
  eventData: EventObject,
): Promise<void> => {
  try {
    await trackGAEvent(eventData)
  } catch (error) {
    // @eslint-disable-next-line @typescript-eslint/no-empty
  }
}
const gracefullyTrackUIEvent = async (
  eventData: TrackingEvent,
): Promise<void> => {
  try {
    await trackUIEvent(eventData)
  } catch (error) {
    // @eslint-disable-next-line @typescript-eslint/no-empty
  }
}

export const addToBasket = async ({
  region,
  quantity,
  product,
  variant,
  filters,
  experiments,
  searchTerm,
  addonSku,
  customerId,
  soldOutMessage,
  buttonActionName,
  groupCardProject,
  productList,
  selectedAddon,
  itemIndex = 1,
  productsLength = 1,
  pageLocation = '',
  componentName = '',
  trackPersonaliseEvent = false,
  addCardToBasketAndAssignDesign,
  createEmptyDesign,
  addToBasketMutation,
  itsNotYouMessage,
  requiresEditingValue = DEFAULT_REQUIRES_EDITING,
  giftXSellTrackingData,
  onTackingInfo,
}: AddToBasketParams): Promise<AddToBasketResult> => {
  const { mnpg_ui_events_api_correlation_id: correlationId } =
    getBrowserCookies()

  const handleMutations = async ({
    productSku,
    shouldCreateEmptyDesign,
  }: {
    productSku: string
    shouldCreateEmptyDesign: boolean
  }) => {
    if (shouldCreateEmptyDesign) {
      const { design } = await createEmptyDesign({
        cardSku: productSku,
        cardId: product.id,
      })

      return {
        ...(await addCardToBasketAndAssignDesign({
          designId: design.id,
          cardSku: productSku,
          addonSku: addonSku || '',
          quantity,
        })),
        error: undefined,
      }
    }
    const handleMutationResult = await handleMutation({
      id: product.id,
      sku: productSku,
      quantity,
      addonSku,
      addToBasketMutation,
      defaultErrorMessage: soldOutMessage,
      concurrentModificationErrorMessage: itsNotYouMessage,
    })

    return handleMutationResult
  }

  const handleAddToBasket = async (shouldCreateEmptyDesign = false) => {
    if (variant.sku) {
      try {
        const {
          error,
          basketId,
          totalItems,
          isGreetingsCardsInBasket,
          isBasketTypeInResponse,
        } = await handleMutations({
          productSku: variant.sku,
          shouldCreateEmptyDesign,
        })

        /* istanbul ignore else */
        if (isBasketTypeInResponse) {
          await gracefullyTrackUIEvent(
            createCustomisationCompletedEvent({
              correlationId,
              productId: product.id,
              store: region.toUpperCase(),
              source: getDeviceType(),
              customerId,
            }),
          )

          const trackingInfo =
            onTackingInfo?.({ product }) ??
            getGAEventLabels({
              product,
              productList,
              selectedAddon,
              itemIndex,
              productsLength,
              pageLocation,
              giftXSellTrackingData,
            })

          await gracefullyTrackGAEvent(
            createAddToBasketWithAddonGAEvent({
              quantity,
              product,
              variant,
              selectedAddon,
              basketId,
              totalItems,
              ...trackingInfo,
            }),
          )
        }

        return { error, isGreetingsCardsInBasket }
      } catch (error) {
        return { error }
      }
    }
    logger.fixImmediately(
      `Failed to add item to basket - product with id "${product.id}" has a missing variant sku and could not be added to basket`,
    )

    return {
      error: soldOutMessage,
    }
  }

  // it happens always on add to basket clicked
  await gracefullyTrackUIEvent(
    createProductSelectedEvent(
      getProductSelectedEventData({
        correlationId,
        product,
        region,
        customerId,
        departments: /* istanbul ignore next */ product.category?.department
          ? [product.category?.department as string]
          : [],
        experiments,
        filters,
        searchTerm,
      }),
    ),
  )

  if (
    requiresEditingValue === false &&
    buttonActionName === ButtonActionName.ADD_TO_BASKET
  ) {
    const { error } = await handleAddToBasket(true)
    /* istanbul ignore next */
    if (error) return { error }
    return {
      windowLocation: `/${region}/upgrade-your-card/`,
    }
  }

  if (groupCardProject) {
    const routeParameter =
      groupCardProject === 'new' ? 'create' : groupCardProject
    return {
      windowLocation: `/${region}/group-cards/${routeParameter}/?productId=${product.id}&productVariantSku=${variant.sku}`,
    }
  }

  if (product.customisable) {
    if (trackPersonaliseEvent) {
      trackGAEvent(
        createPersonaliseGAEvent({
          componentName,
          productData: {
            id: product.id,
            name: product.title,
            price: product.masterVariant.price.centAmount,
            quantity: 1,
            category: product.category.name,
            variant: variant.title,
          },
        }),
      )
    }

    return {
      windowLocation: `/${region}/customise/product/${product.id}/${
        variant.key
      }/?${getQueryStringForEditorLocation({
        sku: variant.sku,
        quantity,
        addonSku,
      })}`,
    }
  }

  const { error, isGreetingsCardsInBasket } = await handleAddToBasket()
  if (error) return { error }
  return {
    nextPage: isGreetingsCardsInBasket ? 'basket' : 'add-a-card',
  }
}
