import React, { FC, useEffect } from 'react'
import { Box, Text } from '@moonpig/launchpad-components'
import { Markup } from '@moonpig/web-core-utils'
import { LogoPlusPill } from '@moonpig/launchpad-assets'
import type { ModuleContext, ModuleMemberPricingHeader } from '../types'
import { useProductsLocaleText } from '../../locale'
import { useGetMembershipDetails } from '../../queries/getMembershipDetails'
import { useProductStore } from '../../contexts/product'
import { useTracking } from '../../contexts/tracking'

type Props = {
  module: ModuleMemberPricingHeader
  context: ModuleContext
}

export const MemberPricingHeaderModule: FC<Props> = ({ context }) => {
  const localiseProductsText = useProductsLocaleText()
  const membershipDetails = useGetMembershipDetails(context)
  const [productState] = useProductStore()
  const { promotionImpression } = useTracking()

  const offerPercent =
    productState.product.primaryProductPill?.displayVariant === 'PLUS' ? 50 : 30

  useEffect(() => {
    if (membershipDetails) {
      promotionImpression({
        componentName: `membership`,
        description: `${offerPercent} percent off any size card`,
        itemId: 'membership-plus',
      })
    }
  }, [promotionImpression, offerPercent, membershipDetails])

  if (membershipDetails) {
    return (
      <Box
        mb={6}
        data-testid="module-member-pricing-header"
        color={'colorTextHeadline'}
      >
        <LogoPlusPill width={64} aria-hidden display="block" />
        <Box mt={4} typography={'typeBodyLabel'}>
          <Markup
            htmlString={localiseProductsText(
              'member_pricing_header.title',
              membershipDetails.customerName,
              offerPercent,
            )}
          />
        </Box>
        <Text typography={'typeBodyCaption'}>
          {localiseProductsText('member_pricing_header.subtitle')}
        </Text>
      </Box>
    )
  }

  return null
}
