import { ApolloError } from '@apollo/client'
import { logger } from '@moonpig/web-core-monitoring'

export const getHandlerForProductPersonalisationApiError = (
  productId: string,
) => {
  return (error: Partial<ApolloError>) => {
    logger.warning(
      `Unable to query personalisation for productId ${productId}`,
      {},
      error,
    )
  }
}
