import { ProductInfoProduct, Variant } from '../../types'
import { GetProductWithAddonsGQLQuery } from '../../queries/useProductWithAddonsQuery/__generated__/useProductWithAddonsQuery'

export const mergeProductWithAddons = ({
  product,
  addonsData,
}: {
  product: ProductInfoProduct
  addonsData: GetProductWithAddonsGQLQuery | undefined
}): ProductInfoProduct => {
  const productWithAddons = JSON.parse(JSON.stringify(product))
  const addonsProducts = addonsData?.productLookup.products
  const productVariants = addonsProducts?.length
    ? addonsProducts[0].variants
    : null
  const productMasterVariant = addonsProducts?.length
    ? addonsProducts[0].masterVariant
    : null

  const productVariantWithAddons = productWithAddons.variants.map(
    (variant: Variant) => {
      const matchedVariants = productVariants?.find(
        addonVariant => addonVariant.sku === variant.sku,
      )

      const matchedAddons = matchedVariants?.addons
      return {
        ...variant,
        addons: matchedAddons || [],
      }
    },
  )

  productWithAddons.variants = productVariantWithAddons
  productWithAddons.masterVariant = {
    ...productWithAddons.masterVariant,
    addons: productMasterVariant?.addons || [],
  }

  return productWithAddons
}
