import { Module } from '../../types'

export const createCardOutOfStockMobileConfiguration = (): Module[] => {
  return [
    { __typename: 'ModuleImages' },
    { __typename: 'ModuleOutOfStock' },
    { __typename: 'ModuleMoreInfo' },
    { __typename: 'ModulePeopleAlsoViewedVariant' },
  ]
}
