import { DepartmentsEnum } from '@moonpig/web-explore-types-graphql'

export const IMAGE_URL_SUFFIX = `?w=400`
export const GALLERY_PAGE_SIZE = 24
export const GALLERY_LOAD_MORE_THRESHOLD = 8
export const GALLERY_LOAD_MORE_PAGES = 3
export const COOKIE_MAX_CHARACTERS = 4096
export const MULTI_SELECTABLE_FACET_CATEGORY = 'price'
export const DESKTOP_MODAL_FILTERS_MENU_WIDTH = '350px'
export const FILTERS_MENU_OFFSET = 64
export const FILTERS_MENU_RESULTS_CTA_CONTAINER_HEIGHT = 85

export const PARENT_DEPARTMENTS = [
  'ALL_CARDS' as DepartmentsEnum,
  'ALL_GIFTS' as DepartmentsEnum,
  'ALL_FLOWERS_AND_PLANTS' as DepartmentsEnum,
]

export const SEARCH_TITLE_FOR_TRACKING = 'Search Results'

export const SEARCH_PAGE_TYPE = 'Search'
export const GALLERY_PAGE_TYPE = 'Gallery'
export const DYNAMIC_PAGE_TYPE = 'Dynamic'
export const PAY_PER_CLICK_PAGE_TYPE = 'PayPerClick'
export const GALLERY_RESULTS_TYPE = 'gallery results'
export const SEARCH_RESULTS_TYPE = 'search results'
export const DYNAMIC_RESULTS_TYPE = 'dynamic results'

export const PRODUCT_ID_TO_VIDEOID_MAP: Record<
  string,
  { videoId: string; isLetterbox: boolean }
> = {
  'product-id': { videoId: 'JHr1OXbUPPs', isLetterbox: true },
  ar421325: { videoId: 'upmd4pdBMyw', isLetterbox: false },
  ar424469: { videoId: 'XaKRHXj0WLg', isLetterbox: false },
  ar423083: { videoId: 'SVu5d45LLd4', isLetterbox: true },
  ar227327: { videoId: 'bH6hr6AKFeE', isLetterbox: false },
  ar421616: { videoId: '9_crSf7ORnU', isLetterbox: false },
  ar424733: { videoId: '--4nNd2wT-M', isLetterbox: false },
  ar425187: { videoId: 'E8dc6ZOJaF4', isLetterbox: false },
  ar420361: { videoId: 'ECrQzwuo22I', isLetterbox: false },
  ar421615: { videoId: '_1gQeaKKGeg', isLetterbox: false },
  ar424030: { videoId: 'dHWbsbYVAIg', isLetterbox: true },
}

export const notRudeFilter = {
  facetKey: 'not-rude',
  group: 'not rude',
  userApplied: true,
}
