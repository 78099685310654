import { createContext, useContext } from 'react'
import { Dispatcher, State } from './types'

export const InitialState: State = {
  recentlyViewedProducts: [],
}

export const stateContext = createContext<State>(InitialState)

export const useRecentlyViewedState = () => {
  const context = useContext(stateContext)
  /* istanbul ignore next */
  if (!context) {
    throw new Error('RecentlyViewedProvider is missing.')
  }
  return context
}

export const dispatcherContext = createContext<Dispatcher>({
  addToRecentlyViewed: () => {},
})

export const useRecentlyViewedDispatcher = () => {
  const context = useContext(dispatcherContext)
  /* istanbul ignore next */
  if (!context) {
    throw new Error('RecentlyViewedProvider is missing.')
  }
  return context
}
