import { Module } from '../../types'

export const createCardUpsellSimplificationNoECardMobileConfiguration =
  (): Module[] => {
    return [
      { __typename: 'ModuleImages' },
      { __typename: 'ModuleMemberPricingHeader' },
      { __typename: 'ModuleFromPrice' },
      { __typename: 'ModuleQuantity' },
      { __typename: 'ModulePromotions' },
      { __typename: 'ModuleDispatch' },
      { __typename: 'ModuleMoreInfo' },
      { __typename: 'ModulePeopleAlsoViewedVariant' },
      { __typename: 'ModuleButtons' },
    ]
  }
