import React, { FC } from 'react'
import { Box, Flex, Text } from '@moonpig/launchpad-components'
import { formatPrice } from '@moonpig/web-core-utils'
import { useLanguage, useStoreId } from '@moonpig/web-core-stores'
import { useCoreLocaleText } from '@moonpig/web-core-locale-text'
import { MULTI_CARD_DISCOUNTS } from '@moonpig/web-core-brand/config'
import { styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { useProductStore } from '../../contexts/product'
import { ModuleContext, ModulePrice } from '../types'
import { useProductsLocaleText } from '../../locale'
import { isCardProduct } from '../../utils/isCardProduct'
import { ProductPrice } from '../../components/ProductPrice'

const StyledBasePrice = styled(Text)<{
  discount: number
}>`
  ${({ discount }) => `
    text-decoration: ${discount ? 'line-through' : 'none'};
  `}
`

const StyledDiscountedPrice = styled(Text)`
  ${s({
    ml: 3,
    color: 'colorFeedbackSuccess',
    fontWeight: 'bold',
  })}
`

const StyledLabel = styled(Text)`
  ${s({
    m: 0,
    p: 0,
    mb: 4,
    color: 'colorTextHeadline',
    typography: { xs: 'typeMobileDisplay03', md: 'typeDesktopDisplay05' },
  })}
`

type Props = {
  context: ModuleContext
  module: ModulePrice
}

export const PriceModule: FC<Props> = () => {
  const [{ product, variant, quantity }] = useProductStore()
  const storeId = useStoreId()
  const language = useLanguage()
  const localiseCoreText = useCoreLocaleText()
  const localiseText = useProductsLocaleText()

  const isCard = isCardProduct(product.category.department)

  const { minimumQuantity, discountedPercentage, fullPrice } = variant
  const { centAmount, fractionDigits, currencyCode } = variant.price
  const { discount, code } = MULTI_CARD_DISCOUNTS[storeId].reduce(
    (acc, current) => (quantity >= current.minimumQuantity ? current : acc),
    { discount: 0, code: '' },
  )

  const discountText = discount
    ? `(${localiseText('multicard.discount', {
        discount,
      })}${code && ' with code '}${code})`
    : ''

  const regularTotalPrice = formatPrice(
    centAmount,
    fractionDigits,
    currencyCode,
    quantity,
    language,
  )

  const discountCentAmount = (centAmount * discount) / 100
  const discountedTotalPrice = discount
    ? formatPrice(
        centAmount - discountCentAmount,
        fractionDigits,
        currencyCode,
        quantity,
        language,
      )
    : ''

  return isCard ? (
    <Flex
      alignContent="center"
      justifyContent="space-between"
      data-testid="module-price"
      mb={6}
    >
      <StyledLabel data-testid="price-title">
        {localiseCoreText('common.price')}
      </StyledLabel>
      <Flex flexDirection="column" alignItems="end">
        <Text
          as="p"
          color={'colorTextHeadline'}
          mb={0}
          aria-label={localiseText(
            'multicard.regular_price_and_discounted_price',
            {
              regularPrice: regularTotalPrice,
              discountedPrice: discountedTotalPrice,
              code,
              discount,
            },
          )}
        >
          <StyledBasePrice discount={discount} aria-hidden>
            {regularTotalPrice}
          </StyledBasePrice>
          <StyledDiscountedPrice aria-hidden>
            {discountedTotalPrice}
          </StyledDiscountedPrice>
        </Text>
        <Text
          as="p"
          color="colorFeedbackSuccess"
          fontWeight="bold"
          mb={0}
          aria-hidden
        >
          {discountText}
        </Text>
      </Flex>
    </Flex>
  ) : (
    <Box mb={6} data-testid="module-price">
      <ProductPrice
        priceCentAmount={centAmount}
        currencyCode={currencyCode}
        fractionDigits={fractionDigits}
        minimumQuantity={minimumQuantity}
        fullPriceCentAmount={fullPrice?.centAmount}
        discountedPercentage={discountedPercentage}
      />
    </Box>
  )
}
