import { gql } from '@moonpig/web-core-graphql'
import { ADDONS_FIELDS_FRAGMENT } from '../useProductWithAddonsQuery'

/* istanbul ignore next */
export const GetUpsellProductGQL = gql`
  ${ADDONS_FIELDS_FRAGMENT}
  query GetUpsellProduct($productId: String!) {
    productLookup(productIds: [$productId]) {
      products {
        title
        slug
        id
        description
        dependencies
        customisable
        isLandscape
        rating {
          count
          score
        }
        category {
          id
          name
          slug
          department
        }
        primaryProductPill {
          displayLabel
          displayVariant
        }
        productPills {
          displayLabel
          displayVariant
        }
        masterVariant {
          key
          title
          price {
            display
            centAmount
            currencyCode
            fractionDigits
          }
          fullPrice {
            display
            centAmount
            currencyCode
            fractionDigits
          }
          discountedPercentage
          minimumQuantity
          images {
            url
          }
          masterImage {
            url
          }
          productImages {
            mainImage {
              medium {
                jpgUrl
              }
            }
            images {
              thumb {
                jpgUrl
              }
              small {
                jpgUrl
              }
              medium {
                jpgUrl
              }
              large {
                jpgUrl
              }
              extraLarge {
                jpgUrl
              }
              original {
                jpgUrl
              }
            }
          }
          addons(clientUsesDescription: true) {
            ...AddonsFields
          }
          inStock
          sku
          bundles {
            description
            price {
              currencyCode
              centAmount
              fractionDigits
            }
            discountPercentage
            size
          }
          capabilities {
            video
          }
          quantityOption {
            ... on FixedQuantity {
              required
            }
            ... on RangeQuantity {
              minimum
            }
          }
        }
        variants {
          key
          title
          price {
            display
            currencyCode
            centAmount
            fractionDigits
          }
          fullPrice {
            display
            centAmount
            currencyCode
            fractionDigits
          }
          discountedPercentage
          inStock
          minimumQuantity
          sku
          addons(clientUsesDescription: true) {
            ...AddonsFields
          }
          bundles {
            description
            price {
              currencyCode
              centAmount
              fractionDigits
            }
            discountPercentage
            size
          }
          capabilities {
            video
          }
        }
        hasAugmentedReality
        isSponsored
        clickRankDocumentCount
      }
    }
  }
`
