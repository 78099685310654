import { createContext, useContext } from 'react'
import { State } from './types'

const initialState: State = {
  membershipInformation: null,
}

export const membershipStateContext = createContext(initialState)

export const useMembershipContext = (): State => {
  const context = useContext(membershipStateContext)
  /* istanbul ignore next */
  if (!context) {
    throw new Error('MembershipProvider is missing.')
  }
  return context
}
