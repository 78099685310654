/* eslint-disable @typescript-eslint/no-explicit-any */
import { DateTime, Duration } from 'luxon'
import { Store } from '@moonpig/web-core-types'
import { useCoreLocaleText } from '@moonpig/web-core-locale-text'
import { isWeekendOrBankHoliday } from '../../utils/date-time'
import { DispatchCutOff } from '../../types'

const COUNTDOWN_PLACEHOLDER = '{{countdown}}'
const timeRegexp = /^(?<hours>[0-1]?[0-9]|2[0-3]):(?<minutes>[0-5][0-9])$/

const tryParseCutOffTime = (
  dateTime: DateTime,
  cutOffTime?: string,
): DateTime | undefined => {
  const time = cutOffTime?.match(timeRegexp)

  return time && time.groups
    ? dateTime.startOf('day').plus(Duration.fromObject(time.groups as any))
    : undefined
}

const hasTimeElapsed = (cutOffTime?: DateTime) =>
  cutOffTime && cutOffTime.diffNow().milliseconds < 0

const formatMessage = (
  localise: ReturnType<typeof useCoreLocaleText>,
  now: DateTime,
  cutOffMessage?: string,
  cutOffTime?: DateTime,
) => {
  if (cutOffMessage?.includes(COUNTDOWN_PLACEHOLDER) && cutOffTime) {
    const diff = cutOffTime.diff(now).rescale()

    const hrs = localise(diff.hours === 1 ? 'common.hr' : 'common.hrs')
    const mins = localise(diff.minutes === 1 ? 'common.min' : 'common.mins')

    return cutOffMessage.replace(
      COUNTDOWN_PLACEHOLDER,
      diff.toFormat(`h '${hrs}' m '${mins}'`),
    )
  }

  return cutOffMessage || ''
}

export const calculateCutOff = (
  store: Store,
  dispatchCutOff: DispatchCutOff | null,
  localise: ReturnType<typeof useCoreLocaleText>,
) => {
  const now = DateTime.local({
    zone: store.locale.timeZone,
    locale: store.locale.language,
  }).startOf('minute')

  const cutOff = isWeekendOrBankHoliday(now, store)
    ? dispatchCutOff?.weekendsAndBankHolidays
    : dispatchCutOff?.weekdays

  const cutOffTime = tryParseCutOffTime(now, cutOff?.time)
  const hasMessage = Boolean(cutOff?.message)
  const hasElapsed = hasTimeElapsed(cutOffTime)

  return {
    isVisible: hasMessage && !hasElapsed,
    message: formatMessage(localise, now, cutOff?.message, cutOffTime),
  }
}
