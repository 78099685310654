import { Module } from '../../types'

export const createCardOutOfStockDesktopConfiguration = (): Module[] => {
  return [
    {
      __typename: 'ModuleTwoColumn',
      left: [{ __typename: 'ModuleImages' }],
      right: [
        { __typename: 'ModuleOutOfStock' },
        { __typename: 'ModuleMoreInfo' },
      ],
      ratio: '50/50',
    },
    { __typename: 'ModulePeopleAlsoViewedVariant' },
  ]
}
