import { POSTCARD_SIZE_ID, SQUARE_CARD_SIZE_ID } from '../../constants'

type Props = {
  isLandscape: boolean
  variantKey: string
}

export const getCardFormat = ({ isLandscape, variantKey }: Props) => {
  switch (variantKey) {
    case POSTCARD_SIZE_ID:
      return 'postcard'
    case SQUARE_CARD_SIZE_ID:
      return 'square'
    default:
      return isLandscape ? 'landscape' : 'portrait'
  }
}
