import { gql, useQuery } from '@moonpig/web-core-graphql'
import { isLoggedIn } from '@moonpig/web-core-auth'
import { getBrowserCookies } from '@moonpig/web-core-cookies'
import { getCustomerId } from './getCustomerId'
import {
  UseCustomerIdQuery,
  UseCustomerIdQueryVariables,
} from './__generated__/useCustomerId'

export const useCustomerIdQuery = gql`
  query UseCustomerIdQuery($isAuthenticated: Boolean!) {
    me @include(if: $isAuthenticated) {
      customer {
        id
      }
    }
  }
`

export const useCustomerId = () => {
  const cookies = getBrowserCookies()
  const isAuthenticated = isLoggedIn(cookies)

  const { data, error } = useQuery<
    UseCustomerIdQuery,
    UseCustomerIdQueryVariables
  >(useCustomerIdQuery, { variables: { isAuthenticated }, ssr: false })

  if (error) return undefined

  const customerId = getCustomerId(data)

  return customerId
}
