import { styled } from '@moonpig/launchpad-utils'
import { Flex } from '@moonpig/launchpad-components'
import { system as s } from '@moonpig/launchpad-system'

export const StyledFlex = styled(Flex)`
  ${s({
    bgcolor: 'colorInteractionSelectedState',
    borderRadius: 2,
    mb: 6,
  })}

  &:has(> button:hover) {
    ${s({
      bgcolor: 'colorInteractionButtonHover',
    })}
  }
`

export const StyledButton = styled.button`
  ${s({
    borderRadius: 2,
    width: '100%',
    p: '5px',
    color: 'colorBackground07',
    bgcolor: 'colorBackground01',
    borderTop: 2,
    borderBottom: 2,
  })}

  &.is-selected {
    ${s({
      color: 'colorInteractionSelectedState',
      bgcolor: 'colorBackground03',
    })}
  }

  &:hover {
    ${s({
      bgcolor: 'colorBackground06',
      color: 'colorInteractionButtonHover',
    })}
  }

  &:first-child {
    ${s({
      borderRadius: '6px 0 0 6px',
      borderColor: 'colorInteractionSelectedState',
      borderLeft: 2,
    })}
  }

  &:last-child {
    ${s({
      borderRadius: '0 6px 6px 0',
      borderColor: 'colorInteractionSelectedState',
      borderRight: 2,
    })}
  }

  &:first-child:hover {
    ${s({
      borderColor: 'colorInteractionButtonHover',
    })}
  }

  &:last-child:hover {
    ${s({
      borderColor: 'colorInteractionButtonHover',
    })}
  }
`
