import React, { FC } from 'react'
import { DepartmentsEnum } from '@moonpig/web-shared-types-graphql/graphqlTypes'
import { useCoreLocaleText } from '@moonpig/web-core-locale-text'
import { Box } from '@moonpig/launchpad-components'
import { QuantitySelector } from '../../components/QuantitySelector'
import { ModuleContext, ModuleQuantity } from '../types'
import { isCardProduct } from '../../utils/isCardProduct'
import { useProductStore } from '../../contexts/product'
import { E_CARD_SIZE_ID } from '../../constants'

const MAX_CARD_QUANTITY = 99
const MAX_GIFT_QUANTITY = 10

type Props = {
  context: ModuleContext
  module: ModuleQuantity
}

export const QuantityModule: FC<Props> = () => {
  const localiseText = useCoreLocaleText()
  const [{ product, variant, quantity }, dispacher] = useProductStore()
  const { id, category, customisable } = product

  const isCustomisable =
    customisable || category.department === DepartmentsEnum.DIGITAL_GIFTS
  const isCard = isCardProduct(category.department)
  const isEcardVariantSelected = variant.key === E_CARD_SIZE_ID

  const shouldShowForGifts = !isCustomisable

  const shouldDisplay = isCard || shouldShowForGifts

  return shouldDisplay ? (
    <Box data-testid="module-quantity" mb={6}>
      <QuantitySelector
        id={`${id}-quantity-select`}
        quantity={quantity}
        onQuantityChange={dispacher.selectQuantity}
        label={localiseText('common.quantity')}
        width={1}
        disabled={isEcardVariantSelected}
        fullWidth
        maxQuantity={isCard ? MAX_CARD_QUANTITY : MAX_GIFT_QUANTITY}
      />
    </Box>
  ) : /* istanbul ignore next */ null
}
