import React, { FC } from 'react'
import { Box, Rating } from '@moonpig/launchpad-components'
import { ModuleContext, ModuleRating } from '../types'
import { useProductStore } from '../../contexts/product'
import { useProductsLocaleText } from '../../locale'

type Props = {
  context: ModuleContext
  module: ModuleRating
}

export const RatingModule: FC<Props> = () => {
  const [{ product }] = useProductStore()
  const t = useProductsLocaleText()

  return (
    <>
      {product.rating && (
        <Box mb={6} data-testid="module-rating">
          <Rating
            rating={product.rating.score}
            totalRatingsText={
              product.rating.count
                ? `(${t('rating.rating_count', product.rating.count)})`
                : undefined
            }
            totalRatingsScreenReaderText={
              product.rating.count ? t('rating.total_reviews') : undefined
            }
            starSize={24}
          />
        </Box>
      )}
    </>
  )
}
