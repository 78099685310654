import React, { FC } from 'react'
import { Grid, Box } from '@moonpig/launchpad-components'
import styled from 'styled-components'
import { breakpointUp } from '@moonpig/launchpad-utils'
import { ModuleTwoColumn, Module, ModuleContext } from '../types'

type Props = {
  module: ModuleTwoColumn
  context: ModuleContext
  map: (module: Module, props: ModuleContext) => JSX.Element | null
}

const StyledLeftColumn = styled(Box)<{ ratio: string | undefined }>`
  width: 100%;
  ${breakpointUp('md')} {
    width: ${({ ratio }) => (ratio === '50/50' ? 50 : 60)}%;
  }
`
const StyledRightColumn = styled(Box)<{ ratio: string | undefined }>`
  width: 100%;
  ${breakpointUp('md')} {
    width: ${({ ratio }) => (ratio === '50/50' ? 50 : 40)}%;
  }
`

export const TwoColumnModule: FC<Props> = ({
  module: { left, right, ratio },
  context,
  map,
}) => {
  return (
    <Grid data-testid="module-two-column" gap>
      <StyledLeftColumn ratio={ratio}>
        {left.map((m, key) => map(m, { ...context, key }))}
      </StyledLeftColumn>
      <StyledRightColumn ratio={ratio}>
        {right.map((m, key) => map(m, { ...context, key }))}
      </StyledRightColumn>
    </Grid>
  )
}
