import React, { FC } from 'react'
import { Box, Text } from '@moonpig/launchpad-components'
import { IllustrationTruckEnvelope } from '@moonpig/launchpad-assets'
import { AllSystemProps } from '@moonpig/launchpad-system'
import { Banner } from '../../components/Banner'
import { DispatchCutOff } from '../../types'
import { useDispatchCutOffTimer } from './useDispatchCutOff'

type Props = {
  dispatchCutOff: DispatchCutOff
}

export const DispatchBanner: FC<Props & AllSystemProps> = ({
  dispatchCutOff,
  ...rest
}) => {
  const { isVisible, message } = useDispatchCutOffTimer(dispatchCutOff)

  const icon = (
    <Box pr={4}>
      <IllustrationTruckEnvelope width={40} height={40} />
    </Box>
  )

  return (
    <>
      {isVisible && (
        <Banner
          icon={icon}
          testId="banner-dispatch"
          px={{ xs: 6, md: 8 }}
          {...rest}
        >
          <Text typography="typeDisplay06">{message}</Text>
        </Banner>
      )}
    </>
  )
}
