import React, { FC } from 'react'
import { MenuItem as ReachMenuItem } from '@reach/menu-button'
import styled from 'styled-components'
import { system as s } from '@moonpig/launchpad-system'
import { IconSystemTick } from '@moonpig/launchpad-assets'
import { Flex, Text } from '@moonpig/launchpad-components'
import {
  DROPDOWN_ITEM_BORDER_RADIUS,
  DROPDOWN_ITEM_HEIGHT,
  DROPDOWN_ITEM_ICON_SIZE,
} from './constants'

const StyledDropdownMenuItemWrapper = styled.div<{ isSelected: boolean }>`
  position: relative;
  display: block;
  user-select: none;
  cursor: pointer;
  outline: ${({ isSelected }) => (isSelected ? 'none' : 'initial')};

  ${({ isSelected }) =>
    s({
      color: 'colorTextHeadline',
      bgcolor: isSelected ? 'colorBackground03' : 'transparent',
      border: 2,
      borderColor: isSelected ? 'colorInteractionSelectedState' : 'transparent',
    })}

  &:first-child {
    border-radius: ${`${DROPDOWN_ITEM_BORDER_RADIUS} ${DROPDOWN_ITEM_BORDER_RADIUS} 0 0`};
  }

  &:last-child {
    border-radius: ${`0 0 ${DROPDOWN_ITEM_BORDER_RADIUS} ${DROPDOWN_ITEM_BORDER_RADIUS}`};
  }

  &:not(:last-child)::after {
    position: absolute;
    left: -2px;
    bottom: -2px;
    display: ${({ isSelected }) => (isSelected ? 'none' : 'block')};
    content: '';
    width: calc(100% + 4px);
    height: 1px;
    ${s({ bgcolor: 'colorBlack10' })}
  }

  &:hover {
    ${({ isSelected }) =>
      !isSelected &&
      s({
        color: 'colorInteractionButtonHover',
        border: 'colorInteractionButtonHover',
        bgcolor: 'colorBackground06',
      })}

    &::after {
      display: none;
    }
  }
`

const StyledIconSystemTick = styled(IconSystemTick)`
  ${s({ color: 'colorInteractionSelectedState' })}
`

const StyledIconWrapper = styled(Flex)`
  align-items: center;
  width: ${DROPDOWN_ITEM_ICON_SIZE};

  svg {
    width: 100%;
  }
`

const DropdownMenuItemButton = ReachMenuItem

export const DropdownMenuItem: FC<{
  item: {
    value: string
    heading: string
    title?: string
    subtitle?: string
    icon?: JSX.Element
  }
  isSelected: boolean
  onSelectHandler: (value: string) => void
}> = ({ item, isSelected, onSelectHandler }) => {
  const { value, heading, title, subtitle, icon } = item

  return (
    <StyledDropdownMenuItemWrapper key={value} isSelected={isSelected}>
      <DropdownMenuItemButton
        data-testid={`shared-products-dropdown-item-${value}`}
        onSelect={() => onSelectHandler(value)}
      >
        <Flex minHeight={DROPDOWN_ITEM_HEIGHT} pl={4} pr={5} py={4}>
          <StyledIconWrapper>{icon}</StyledIconWrapper>
          <Flex
            flexDirection="column"
            justifyContent="center"
            flexGrow={3}
            pl={7}
          >
            <Text typography="typeButtonLabel">{heading}</Text>
            {title && (
              <Text typography="typeBodyCaption" mb={0}>
                {title}
              </Text>
            )}
            {subtitle && (
              <Text typography="typeButtonLabel" mb={0}>
                {subtitle}
              </Text>
            )}
          </Flex>
          {isSelected && (
            <Flex justifyContent="center" alignItems="center" ml={4}>
              <StyledIconSystemTick width={24} />
            </Flex>
          )}
        </Flex>
      </DropdownMenuItemButton>
    </StyledDropdownMenuItemWrapper>
  )
}
