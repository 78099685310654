import React, { FC, Children, PropsWithChildren } from 'react'
import {
  CarouselState,
  CarouselRoot,
  CarouselControls,
  UnstyledCarouselScrollArea,
  CarouselPreviousButton,
  CarouselNextButton,
  CarouselItem,
} from '@moonpig/launchpad-components'
import { styled, breakpointUp, breakpointDown } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'

export type Props = {
  hideScrollButtons?: boolean
  itemsGap?: { xs?: number; md?: number }
  noSpacing?: boolean
}

const StyledCarouselItem = styled(CarouselItem)`
  &:first-child {
    ${s({ ml: { xs: 5, md: 0 } })}
  }

  &:last-child {
    ${s({ mr: { xs: 5, md: 0 } })}
  }
`

const StyledCarouselScrollArea = styled(UnstyledCarouselScrollArea)<{
  centerSlides?: boolean
  gap?: { xs?: number; md?: number }
}>`
  ${({ gap }) =>
    s({
      gap: gap || { xs: 12, md: 16 },
    })};

  ${breakpointUp('md')} {
    justify-content: ${({ centerSlides }) =>
      centerSlides ? 'center' : 'initial'};
  }
`

const StyledPreviousButton = styled(CarouselPreviousButton)`
  ${breakpointDown('md')} {
    display: none;
  }
`

const StyledNextButton = styled(CarouselNextButton)`
  ${breakpointDown('md')} {
    display: none;
  }
`

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const StyledCarouselRoot = styled(({ noSpacing, ...props }) => (
  <CarouselRoot {...props} />
))<{ noSpacing: boolean }>`
  ${({ noSpacing }) =>
    s({
      mx: noSpacing ? 0 : -6,
      px: noSpacing ? 0 : 6,
    })};

  ${breakpointDown('md')} {
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
  }
`

export const Carousel: FC<PropsWithChildren<Props>> = ({
  hideScrollButtons,
  itemsGap,
  children,
  noSpacing = false,
}) => {
  return (
    <CarouselState>
      <StyledCarouselRoot noSpacing={noSpacing}>
        <CarouselControls>
          {!hideScrollButtons && <StyledPreviousButton />}
          {!hideScrollButtons && <StyledNextButton />}
        </CarouselControls>
        <StyledCarouselScrollArea
          centerSlides={hideScrollButtons}
          data-testid="lp-components-carousel-scroll-area"
          gap={itemsGap}
        >
          {Children.map(children, (item, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <StyledCarouselItem tabIndex={undefined} key={index}>
              {item}
            </StyledCarouselItem>
          ))}
        </StyledCarouselScrollArea>
      </StyledCarouselRoot>
    </CarouselState>
  )
}
