import React, { FC, useState } from 'react'
import {
  Accordion,
  AccordionContent,
  AccordionHeading,
  Box,
} from '@moonpig/launchpad-components'
import { useCoreLocaleText } from '@moonpig/web-core-locale-text'
import { AccordionSection } from '../../types'
import { ModuleContext, ModuleThingsToKnow } from '../types'
import { useProductStore } from '../../contexts/product'
import { getAccordionSections } from './getAccordionSections'
import { trackAccordionSectionClick } from './trackAccordionSectionClick'

type Props = {
  context: ModuleContext
  module: ModuleThingsToKnow
}

export const ThingsToKnowModule: FC<Props> = () => {
  const [openSections, setOpenSections] = useState([
    AccordionSection.DESCRIPTION,
  ])
  const localiseText = useCoreLocaleText()
  const [{ product }] = useProductStore()
  const sections = getAccordionSections({ product, localiseText })

  return (
    <Box my={6}>
      <Accordion
        type="multiple"
        data-testid="module-things-to-know"
        defaultValue={[AccordionSection.DESCRIPTION]}
        onValueChange={(updatedSections: AccordionSection[]) => {
          trackAccordionSectionClick({
            currentSections: openSections,
            updatedSections,
            productId: product.id,
          })
          setOpenSections(updatedSections)
        }}
      >
        {sections.map(section => (
          <AccordionContent
            key={section.sectionType}
            heading={
              <AccordionHeading level="h2">{section.title}</AccordionHeading>
            }
            value={section.sectionType}
          >
            {section.render}
          </AccordionContent>
        ))}
      </Accordion>
    </Box>
  )
}
