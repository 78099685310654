import { createContext, useContext, useEffect } from 'react'
import { Dispatcher, ProductModalClosed, ProductModalShown } from './types'

export const dispatcherContext = createContext<Dispatcher | null>(null)

export const useProductModal = () => {
  const context = useContext(dispatcherContext)
  /* istanbul ignore next */
  if (!context) {
    throw new Error('ProductModalProvider is missing.')
  }
  return context
}

type Events = ProductModalClosed | ProductModalShown

export const eventContext = createContext<Events | null>(null)

export const useProductModalEvents = (cb: (e: Events) => void) => {
  const context = useContext(eventContext)

  useEffect(() => {
    /* istanbul ignore next */
    if (context) {
      cb(context)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context])
}
