import { DepartmentsEnum } from '@moonpig/web-shared-types-graphql/graphqlTypes'
import { ProductInfoDictionary } from '../../components/ProductInfo/ProductInfo.locale'

export const getVariantSelectorTitle = (
  categoryDepartment: string,
  localiseText: (key: ProductInfoDictionary) => string,
) => {
  return localiseText(
    categoryDepartment === DepartmentsEnum.FLOWERS
      ? 'common.select_bouquet_size'
      : 'common.select_size',
  )
}
