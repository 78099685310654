import React, { FC, useEffect } from 'react'
import { styled, breakpoint } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { setBrowserCookie } from '@moonpig/web-core-cookies'
import { DialogOverlay } from '@reach/dialog'
import { Box, Alert, Heading } from '@moonpig/launchpad-components'
import { useLocaleText } from './applyDiscountVoucher.locale'

export const OVERLAY_TEST_ID = 'mp-loyalty-applyVoucherOverlay'

const MODAL_FROM_BREAKPOINT = 'md'
const StyledOverlay = styled(DialogOverlay).attrs({
  'data-testid': OVERLAY_TEST_ID,
})`
  z-index: 2000;
  ${s({ bgcolor: 'rgba(0, 0, 0, 0.6)' })}
  h2 {
    ${s({
      typography: { xs: 'typeMobileDisplay05', md: 'typeDesktopDisplay06' },
    })}
  }
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  ${breakpoint(MODAL_FROM_BREAKPOINT)} {
    pointer-events: auto;
  }
`

const StyledAlert = styled(Alert)`
  button:not(:disabled):focus {
    ${s({ color: 'colorTextBody' })}
  }
`

export type ApplyDiscountVoucherModule = {
  uqd?: string
}

export const ApplyDiscountVoucher: FC<ApplyDiscountVoucherModule> = ({
  uqd,
}) => {
  const localiseText = useLocaleText()
  const [showNotification, setShowNotification] = React.useState(false)
  const expireDateInSeconds = 604800
  useEffect(() => {
    if (uqd) {
      setBrowserCookie('pendingvoucher', uqd, {
        path: '/',
        maxAge: expireDateInSeconds,
      })
      setShowNotification(true)
    }
  }, [uqd])

  return (
    <>
      <StyledOverlay
        isOpen={showNotification}
        onDismiss={() => setShowNotification(false)}
      >
        <Box display="flex" justifyContent="center" flexDirection="row">
          <StyledAlert
            width={{ md: 1, lg: 1 / 2 }}
            variant="success"
            onClickClose={() => setShowNotification(false)}
          >
            <Heading
              level="h2"
              mb={0}
              typography={{
                xs: 'typeMobileDisplay03',
                md: 'typeDesktopDisplay04',
              }}
            >
              {localiseText('discounts.discount_heading')}
            </Heading>
            <span>
              {localiseText('discounts.discount_applied_at_checkout')}
            </span>
          </StyledAlert>
        </Box>
      </StyledOverlay>
    </>
  )
}
