import React, { FC } from 'react'
import { Box } from '@moonpig/launchpad-components'
import { useProductStore } from '../../contexts/product'
import { ModuleContext, ModulePills } from '../types'
import { ProductPills } from '../../components/ProductPills'

type Props = {
  module: ModulePills
  context: ModuleContext
}

export const PillsModule: FC<Props> = () => {
  const [
    {
      product: { primaryProductPill, productPills },
    },
  ] = useProductStore()

  const hasProductPills = Boolean(primaryProductPill || productPills?.length)

  return hasProductPills ? (
    <Box data-testid="module-pills" mb={6}>
      <ProductPills
        primaryProductPill={primaryProductPill}
        productPills={productPills}
      />
    </Box>
  ) : null
}
