import React, { FC } from 'react'
import { Box } from '@moonpig/launchpad-components'
import { MultiAddOnSelector } from '../../components/MultiAddOnSelector'
import { useProductStore } from '../../contexts/product'
import { ModuleContext, ModuleAddons } from '../types'

const CONTAINER_XS_PX = 6

type Props = {
  context: ModuleContext
  module: ModuleAddons
}

export const AddonsModule: FC<Props> = () => {
  const [{ variant, addon }, dispatcher] = useProductStore()

  const addons = (variant.addons || []).filter(({ inStock }) => inStock)

  if (!addons.length) {
    return null
  }

  return (
    <Box
      data-testid="module-addons"
      bgcolor="colorBackground06"
      py={8}
      mb={6}
      px={{ xs: CONTAINER_XS_PX, lg: 8 }}
      mx={{ xs: -CONTAINER_XS_PX, md: 0 }}
    >
      <MultiAddOnSelector
        addons={addons}
        selectedAddon={addon}
        onAddClick={addonItem => {
          dispatcher.selectAddon(addonItem)
        }}
        onRemoveClick={() => {
          dispatcher.selectAddon(null)
        }}
      />
    </Box>
  )
}
