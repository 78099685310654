import { gql } from '@moonpig/web-core-graphql'

export const GetMembershipDetailsGQL = gql`
  query GetMembershipDetailsForUser($isLoggedIn: Boolean!) {
    me @include(if: $isLoggedIn) {
      customer {
        id
        firstName
        membership {
          membershipType
        }
      }
    }
  }
`
