import React from 'react'
import { useCoreLocaleText } from '@moonpig/web-core-locale-text'
import { useStoreId } from '@moonpig/web-core-stores'
import { Flex } from '@moonpig/launchpad-components'
import { useRouter } from '@moonpig/web-core-routing'
import { styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { queryParamsBuilder } from '../../utils/queryParamsBuilder'
import { useProductStore } from '../../contexts/product'
import { ModuleContext, ModuleMoreInfo } from '../types'
import { useTracking } from '../../contexts/tracking'

const StyledLink = styled.a`
  display: flex;
  align-items: center;
  min-height: 48px;
  ${s({
    mb: { xs: 6, md: 0 },
  })}
`

export type Props = {
  module: ModuleMoreInfo
  context: ModuleContext
}

export const MoreInfoModule: React.FC<Props> = () => {
  const { selectContent } = useTracking()
  const [{ product, variant }] = useProductStore()
  const router = useRouter()
  const localiseText = useCoreLocaleText()
  const storeId = useStoreId()
  const { slug, category, id } = product
  const linkTitle = localiseText('common.more_information')
  const groupCardProject =
    router.getCurrentRoute<'search'>()?.params?.groupCardProject

  const queryParams = queryParamsBuilder()
    .add('size', variant.key)
    .add('groupCardProject', groupCardProject)
    .build()

  const href = `/${storeId}/${category.slug}/p/${slug}/${id}/${queryParams}`

  return (
    <Flex justifyContent="center" data-testid="module-more-info">
      <StyledLink
        href={href}
        onClick={() =>
          selectContent({
            action: 'view more information',
            component: 'hyperlink',
            label: linkTitle,
          })
        }
      >
        {linkTitle}
      </StyledLink>
    </Flex>
  )
}
