import { gql } from '@moonpig/web-core-graphql'
import { designFields } from '../addDigitalGiftToBasket/fragments'

export const AddToBasketGQL = gql`
  mutation AddToBasketFind($input: AddToBasketInput!) {
    addToBasket(input: $input) {
      __typename
      ... on Basket {
        id
        lineItems {
          productType
          id
          design {
            ...DesignFields
          }
        }
        totalItems
      }
      ... on ConcurrentModificationError {
        message
      }
      ... on ProductNotFoundError {
        message
      }
    }
  }
  ${designFields}
`
