import React, { FC } from 'react'
import { OnConfigureModulesCallback, ModuleContext } from '../types'
import { mapModule } from '../map-modules'
import { useProductStore } from '../../contexts/product'

type Props = {
  onConfigureModules?: OnConfigureModulesCallback
  onMapModule?: typeof mapModule
  context: Omit<ModuleContext, 'key'>
}

export const ModuleLayoutContainer: FC<React.PropsWithChildren<Props>> = ({
  onConfigureModules,
  onMapModule = mapModule,
  context,
  children,
}) => {
  const [{ product, upsellProduct }] = useProductStore()
  const modulesConfiguration =
    onConfigureModules?.({ product, upsellProduct }) || []

  return (
    <>
      {modulesConfiguration.length
        ? modulesConfiguration.map((module, key) =>
            onMapModule(module, {
              key,
              ...context,
            }),
          )
        : children}
    </>
  )
}
