import { createLocaleTextHook } from '@moonpig/web-core-locale-text'

const dictionary = {
  'discounts.discount_applied_at_checkout': {
    'en-GB':
      'Don’t forget to hit “Apply Voucher” in the basket to apply the discount.',
    'nl-NL':
      "Klik of tik in je winkelmandje nog wel even op 'kortingscode toepassen'.",
  },
  'discounts.discount_heading': {
    'en-GB': 'Discount added to basket!',
    'nl-NL': 'Je kortingscode staat klaar!',
  },
}

export const useLocaleText = createLocaleTextHook({
  dictionary,
})
