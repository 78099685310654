import { useQuery } from '@moonpig/web-core-graphql'
import { useLoggedIn } from '@moonpig/web-core-auth'
import { GetMembershipDetailsGQL } from './getMembershipDetails'
import {
  GetMembershipDetailsForUserQuery,
  GetMembershipDetailsForUserQueryVariables,
} from './__generated__/getMembershipDetails'
import { useMembershipContext } from '../../contexts/membership'

type MembershipInformation = {
  customerName: string
}

type UseGetMembershipDetailsInput = {
  flags: Record<string, boolean>
}

type UseGetMembershipDetails = (
  input: UseGetMembershipDetailsInput,
) => MembershipInformation | null

export const useGetMembershipDetails: UseGetMembershipDetails = ({ flags }) => {
  const { loggedIn } = useLoggedIn()
  const { membershipInformation } = useMembershipContext()

  const { data } = useQuery<
    GetMembershipDetailsForUserQuery,
    GetMembershipDetailsForUserQueryVariables
  >(GetMembershipDetailsGQL, {
    variables: { isLoggedIn: loggedIn },
    skip:
      !loggedIn ||
      Boolean(membershipInformation?.customerName) ||
      !flags['loyalty-enable-member-pricing'],
  })

  if (!data && membershipInformation) {
    return membershipInformation
  }

  const customer = data?.me?.customer

  if (customer && customer.membership) {
    return {
      customerName: customer.firstName,
    }
  }

  return null
}
