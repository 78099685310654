import React from 'react'
import { Box, Flex, Container } from '@moonpig/launchpad-components'
import { AllSystemProps } from '@moonpig/launchpad-system'

export const Banner: React.FC<
  React.PropsWithChildren<
    { icon?: JSX.Element; testId?: string } & AllSystemProps
  >
> = ({ icon, testId, children, ...rest }) => (
  <Box data-testid={testId} bgcolor="colorBackground03" {...rest}>
    <Container limitWidth padding={0}>
      <Flex width={1} alignItems="center" justifyContent="center" py={2}>
        {icon && <Flex pr={3}>{icon}</Flex>}
        {children}
      </Flex>
    </Container>
  </Box>
)
