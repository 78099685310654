/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import React, { FC, useEffect, useState } from 'react'
import { Box } from '@moonpig/launchpad-components'
import { useExperiment } from '@moonpig/web-core-experiments'
import { ModuleContext, ModuleImages } from '../types'
import { useTracking } from '../../contexts/tracking'
import { useProductStore } from '../../contexts/product'
import { useFavouritesState, checkFavourited } from '../../contexts/favourites'
import { isCardProduct } from '../../utils/isCardProduct'
import { GiftImageCarousel } from '../../components/GiftImageCarousel'
import { ImageCarouselVariant } from '../../components/ImageCarouselVariant'
import { useHandleFavourite } from '../../utils/favourites'
import { getCardFormat } from './card-format'
import {
  CARD_INSIDE_LEFT_INDEX,
  CARD_INSIDE_RIGHT_INDEX,
  CARDS_WITH_EDITABLE_CONTENT,
  FORMAT_POST_CARD,
  GROUP_CARD_IMAGES,
} from './constants'

type Props = {
  module: ModuleImages
  context: ModuleContext
}

export const ImagesModule: FC<Props> = () => {
  const tracking = useTracking()
  const { favourites } = useFavouritesState()
  const handleFavourites = useHandleFavourite({
    removeWithConfirmation: false,
    tracking: { pageLocation: '', totalCount: 1 },
  })
  const [{ product, isGroupCardSelected }] = useProductStore()
  const cardFormat = getCardFormat(product)
  const editableContentExpEnabled =
    useExperiment('explore-editable-card-content') === 'enabled'

  const [initialIndex, setInitialIndex] = useState(0)

  const getCardImageUrl = (
    image: { url: string },
    imageIndex: number,
  ): string => {
    if (
      cardFormat !== FORMAT_POST_CARD &&
      isGroupCardSelected &&
      [CARD_INSIDE_LEFT_INDEX, CARD_INSIDE_RIGHT_INDEX].includes(imageIndex)
    ) {
      return GROUP_CARD_IMAGES[cardFormat][imageIndex - 1]
    }

    return image.url
  }

  useEffect(() => {
    setInitialIndex(0)
  }, [product])

  useEffect(() => {
    setInitialIndex(isGroupCardSelected ? 1 : 0)
  }, [isGroupCardSelected])

  const imageCarousel = () => {
    if (isCardProduct(product.category.department)) {
      const images = product.masterVariant.images.map((image, imageIndex) => ({
        url: getCardImageUrl(image, imageIndex),
      }))

      if (
        editableContentExpEnabled &&
        CARDS_WITH_EDITABLE_CONTENT.includes(product.id.toUpperCase())
      )
        images.splice(1, 0, {
          url: require(`./assets/${product.id.toUpperCase()}.jpg`),
        })

      return (
        <ImageCarouselVariant
          productId={product.id}
          title={product.title}
          images={images}
          initialSelectedIndex={initialIndex}
          isFavourited={checkFavourited(favourites, product)}
          onFavouriteToggle={() =>
            handleFavourites(product, checkFavourited(favourites, product), 0)
          }
          onImageSelected={({ index }) => {
            tracking.selectContent({
              component: 'view product image',
              label: product.id,
              action: `${index + 1}/${images.length}`,
            })
          }}
        />
      )
    }

    return (
      <GiftImageCarousel
        images={product.masterVariant.images.map(({ url }) => url)}
        title={product.title}
        isFavourited={checkFavourited(favourites, product)}
        onFavouriteToggle={() =>
          handleFavourites(product, checkFavourited(favourites, product), 0)
        }
      />
    )
  }

  return (
    <Box mb={6} data-testid="module-images" position="relative">
      {imageCarousel()}
    </Box>
  )
}
